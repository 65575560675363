

import request from '@/tools/request'
import { EMS_APP_CLIENT_ID } from '@/tools/env'

// 获取图形码
export async function getImageCode(options?: { [key: string]: any } ) {
    return request('/auth/code', {
        method: "GET",
        ...(options || {}),
    })
}

// 注册
export async function register(options: { [key: string]: any }) {
    const params = {
        ...options,
        userType: 'client_user',
        clientId: EMS_APP_CLIENT_ID,
        grantType: 'password',
        tenantId: "000000"
    }
    return request('/auth/register', {
        headers: {
            isToken: false,
            isEncrypt: true,
            repeatSubmit: false
        },
        method: "POST",
        data: (params || {}),
    })
}

// 登录
export async function login(options: { [key: string]: any }) {
    const params = {
        ...options,
        clientId: EMS_APP_CLIENT_ID,
        grantType: 'password',
        tenantId: "000000"
    }
    return request('/auth/login', {
        headers: {
            isToken: false,
            isEncrypt: true,
            repeatSubmit: false
        },
        method: "POST",
        data: params || {},
    })
}


// 退出登录
export async function loginOut() {
    return request('/auth/logout', {
        method: "POST",
    })
}

// 获取用户信息
export async function getUserInfo() {
    return request('/user/client/info', {
        method: "GET",
    })
}

// 获取用户顶级设备配置
export async function getDeviceTopInfo(options: { [key: string]: any }) {
    // /user/device/top/info
    return request('/iot/deviceInfo/list/user', {
        method: "GET",
        params: options
    })
}

// 提交绑定终端
export async function submitDeviceBinding(options: { [key: string]: any }) {
    // '/user/device/binding'
    return request('/iot/ems/binding/ems', {
        method: "POST",
        data: options || {},
    })
}

// 修改用户基本信息
export async function updateUserInfo(options: { [key: string]: any }) {
    return request('/user/client/update/info', {
        method: "POST",
        data: options || {},
    })
}

// 发送邮箱验证码
export async function sendEmailCode(options: { [key: string]: any }) {
    return request('/resource/email/code', {
        method: "POST",
        data: options || {},
    })
}

// 邮箱绑定
export async function bindEmail(options: { [key: string]: any }) {
    return request('/user/client/binding/email', {
        method: "POST",
        params: options
    })
}

// 发送手机号验证码
export async function sendPhoneCode(options: { [key: string]: any }) {
    return request('/resource/sms/code', {
        method: "POST",
        data: options || {},
    })
}

// 绑定手机号
export async function bindPhone(options: { [key: string]: any }) {
    return request('/user/client/binding/sms', {
        method: "POST",
        params: options
    })
}

// 通过原密码修改密码
export async function modifyPasswordByOriginPassword(options: { [key: string]: any }) {
    return request('/user/client/update/password', {
        method: "POST",
        headers: {
            isToken: true,
            isEncrypt: true,
            repeatSubmit: false
        },
        data: options || {},
    })
}


// 通过邮箱修改密码
export async function modifyPasswordByEmail(options: { [key: string]: any }) {
    return request('/user/client/update/email/password', {
        method: "POST",
        headers: {
            isToken: true,
            isEncrypt: true,
            repeatSubmit: false
        },
        data: options || {},
    })
}
// 注销账号
export async function deleteAccount() {
    return request('/auth/logoff', {
        method: "GET",
    })
}

// 天气
export async function getWeather(options: { [key: string]: any }) {
    return request(`/weather-api/data/2.5/forecast?q=${encodeURIComponent(options.city)}&appid=ef3df0779c148255d347b2148410a7c0&units=metric&lang=zh_cn`, {
        method: "GET",
        headers: {
            isToken: false,
            isClientId: false,
        },
    });
}