// 公共组件常见问题
export default {
    'question.commonQuestions': 'Common questions',
    'question.tips1': 'What is the difference between local and cloud version?',
    'question.tips1-1': 'The local version has limited functions, and the cloud version supports more powerful functions, such as remote control, OTA upgrades, and weather warning functions. If the cloud function is not enabled, some functions will be restricted from display, and the content you see when choosing the local and cloud versions may be different.',
    'question.tips2': 'Why choose cloud data backup?',
    'question.tips2-1': "Choosing cloud data backup can save local storage of hems, make better recommendation calculations based on the customer's user habits, and help customers adjust the mode that best suits them.",
    'question.tips-3': 'Why don’t I see much effect after switching modes?',
    'question.tips3-1': 'Energy management takes some time to have a noticeable effect, so it is recommended to compare data for more than one week.',
    'question.tips4': "When configuring a network or adding a device, can't find the router you want to connect to?",
    'question.tips4-1': '*Please make sure the current router has a password set.',
    'question.tips4-2': '*Please make sure that the current router does not require secondary identity and password verification.',
    'question.tips4-3': '*5G Wi-Fi network connection is not supported, please adjust to 2.4G Wi-Fi network connection.',
    'question.tips4-4': '*Please make sure that the router is not set to hide SSID. Routers with hidden SSID cannot be displayed in the list. Please modify the router settings and try to connect again.',
    'question.tips4-5': 'If the above method cannot solve the problem, it is recommended to restart the router or hems and try to reconnect.',
    'question.tips5': 'What should I do if the "Device Offline" prompt appears?',
    'question.tips5-1': 'When the "Device Offline" prompt appears, it may be due to a poor current network environment or a power outage on the device.',
    'question.tips5-2': 'Please try the following:',
    'question.tips5-3': '*Whether the device is connected to a power source;',
    'question.tips5-4': '*Whether the router can connect to the external network;',
    'question.tips5-5': '*Try to power off and restart the device;',
    'question.tips5-6': '*If the router password has changed, please try resetting the device',
    'question.tips6': 'What should I do if I cannot use the original device after changing the password or replacing the router?',
    'question.tips6-1': 'If you change the password or replace the router, you need to connect the device to the power supply and re-connect to the network, re-select the router hotspot, enter the password and reconnect. Click My-Network Configuration Settings to re-enter the network configuration process.',
    'question.tips7': 'How do I add a new device?',
    'question.tips7-1': 'Please switch to the local version to add devices. When the new device is powered on, click “My”, select the corresponding device category, and then select “Add Device” to proceed with the device adding process.',
    'question.tips8': 'What should I do if the device cannot be added?',
    'question.tips8-1': 'Maybe you are not using the local version or the signal is interfered or the device has been bound. Please try to reconnect.',
}
 