// 【公共组件】数据上云
export default {
   'dataToCloud.tips1': '数据上云备份指令已经成功发送，传输历史数据需要等待一段时间，请稍候一些时间后再切换到云端版本查看！',
   'dataToCloud.tips2': '数据备份上云已关闭！',
   'dataToCloud.tips3': '经检查您还没有绑定终端，即将跳转到绑定终端的页面。',
   'dataToCloud.tips4': '当前检测到无网络状态，请联网后再操作！',
   'dataToCloud.tips5': '关闭上传历史数据功能后，离开当前配网的wifi将无法访问数据。确认关闭上传历史数据功能？',
   'dataToCloud.tips6': '数据上云备份：数据云是一个基于云计算技术的数据管理和分析平台，它将数据存储、处理、分析和共享等功能整合在一起，以提供灵活、可扩展和易于使用的数据服务。传输历史数据需要等待一段时间，请耐心等候！',
   'dataToCloud.tips7': '数据上云备份',
   'dataToCloud.tips8': '我知道了, 确认数据上云',
};
