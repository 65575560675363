// 【公共組件】綁定設備
export default {
    "bindDevice.tips1": "請確認需要綁定的設備都已上電後，點擊去綁定設備進行設備綁定。",
    "bindDevice.tips2": "正在搜索設備，請稍候...",
    "bindDevice.tips3": "當前使用雲端版本，無法添加設備，請切換本地版本進行設備添加！！",
    "bindDevice.tips4": "設備綁定成功！",
    "bindDevice.tips5": "設備綁定中，請稍候...",
    "bindDevice.tips6": "請勿移除電源和關掉設備。",
    "bindDevice.tips7": "搜索設備結果",
    "bindDevice.tips8": "沒有搜索到設備，請確認設備都已上電後重試",
    "bindDevice.tips9": "重新搜索",
    "bindDevice.tips10": "設備還未完全通信",
    "bindDevice.tips11": "僅搜索到部分設備",
    "bindDevice.tips12": "繼續等待120s",
    "bindDevice.tips13": "重新搜索綁定",
    "bindDevice.tips14": "請根據以下搜索到的設備進行選擇綁定",
    "bindDevice.backToHome": "返回主頁",
    "bindDevice.select": "選擇",
    "bindDevice.deviceBrand": "設備品牌",
    "bindDevice.deviceModal": "設備型號",
    "bindDevice.deviceType": "設備類型",
    "bindDevice.deviceMacAddress": "設備MAC地址",
    "bindDevice.selectAll": "全選",
    "bindDevice.confirm": "確認無誤",
    "bindDevice.addDevice": "添加設備",
    "bindDevice.searchAgain": "重新搜索",
    "bindDevice.addDeviceTips": "已經綁定過設備，確認增加設備綁定嗎？",
    "bindDevice.addDeviceConfirm": "確認增加綁定",
    "bindDevice.gotoBind": "去綁定設備",

    'bindDevice.searchBtn': '搜索設備',
    'bindDevice.confirmDevice': '確認設備',
    'bindDevice.deviceInit': '設備初始化',
    'bindDevice.text': '綁定設備',
    'bindDevice.selectOperate': '請選擇以下操作',
    'bindDevice.deviceList': '設備列表',
    'bindDevice.partSuccess': '設備僅部分初始化成功',
};
