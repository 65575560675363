import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as toolsStorage from '@/tools/storage'

const cloudInitGuideToLogin = () => {
    const location = useLocation()
    useEffect(() => {
        const arr = ['/account/login', '/account/register', '/account/forgetPassword']
        const userInfo = toolsStorage.getItem('userInfo')
        if(!userInfo) {
            if(arr.includes(location.pathname)) {
                return
            } else {
                window.location.href = '/account/login'
            }
            return
        }  
    }, [])
}

export default cloudInitGuideToLogin