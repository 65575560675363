import React, { useEffect } from 'react'
import router from '../router'
import { useRoutes } from 'react-router-dom'
import initReactFastclick from 'react-fastclick'
import 'default-passive-events' // 解决 https://blog.csdn.net/Wildness_/article/details/123190078
import VConsole from 'vconsole'
import cloudInitGuideToLogin from '@/actions/cloudInitGuideToLogin'
import cloudInit from './actions/cloudInit'
import * as toolsStorage from '@/tools/storage'


function App() {
  const element = useRoutes(router)
  const userInfo = toolsStorage.getItem('userInfo')

  // 接入登录
  cloudInitGuideToLogin()

  // 云端
  cloudInit()
 
  useEffect(() => {
    // saveDomainToStorage() // 打开应用保存域名
    // 移动端调试工具
    if(process.env.BASE_ENV=== 'development') {
      const vConsole = new VConsole()
    }
    initReactFastclick()
  }, [])
  return <>{element}</>
}

export default App
