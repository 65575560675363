// 公共组件版本更新
export default {
    'versionUpdate.tips1': 'New version detected, the update process may take some time. Want to update?',
    'versionUpdate.tips2': 'Updating, the process may take a few minutes. Please wait a few minutes before operating again~~',
    'versionUpdate.tips3': 'Version update failed. Please check the network connection and try again.',
    'versionUpdate.tips4': 'Version update successful!',
    'versionUpdate.tips5': 'No network detected. Please connect to the internet and try again!',
    'versionUpdate.tips6': 'Updating, the process may take a few minutes. During the update, file replacement may cause the page to be inaccessible or refresh automatically. Please manually refresh the page a few minutes later~~',
    'versionUpdate.tips7': 'Current version is up-to-date!!',
    'versionUpdate.currentVersion': 'Current Version',
    'versionUpdate.updateVersion': 'Update Version',
    'versionUpdate.goToUpdate': 'Go to Update',
    "versionUpdate.tips": 'Tips',
    "versionUpdate.know": 'Got it',
}
 