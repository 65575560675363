// 【公共組件】數據上雲
export default {
   'dataToCloud.tips1': '數據上雲備份指令已經成功發送，傳輸歷史數據需要等待一段時間，請稍候一些時間後再切換到雲端版本查看！',
   'dataToCloud.tips2': '數據備份上雲已關閉！',
   'dataToCloud.tips3': '經檢查您還沒有綁定終端，即將跳轉到綁定終端的頁面。',
   'dataToCloud.tips4': '當前檢測到無網絡狀態，請聯網後再操作！',
   'dataToCloud.tips5': '關閉上傳歷史數據功能後，離開當前配網的wifi將無法訪問數據。確認關閉上傳歷史數據功能？',
   'dataToCloud.tips6': '數據上雲備份：數據云是一個基於雲計算技術的數據管理和分析平台，它將數據存儲、處理、分析和共享等功能整合在一起，以提供靈活、可擴展和易於使用的數據服務。傳輸歷史數據需要等待一段時間，請耐心等候！',
   'dataToCloud.tips7': '數據上雲備份',
   'dataToCloud.tips8': '我知道了, 確認數據上雲',
};
