// 多樁
export default {
    // 首頁
    "multiEv.todaysData": "今日數據",
    "multiEv.maxPeakCurrent": "今日最大峰值電流",
    "multiEv.totalElectricityUse": "今日總用電量",
    "multiEv.totalElectricityCost": "今日用電總費用",
    "multiEv.totalChargingTimes": "今日充電次數",
    "multiEv.hourlyData": "當時數據",
    "multiEv.normal": "正常",
    "multiEv.exception": "異常",
    "multiEv.unknown": '未知',
    "multiEv.idle": "閒余",
    "multiEv.charging": "正在充電",
    "multiEv.offline": "離線",
    "multiEv.liveData": "實時數據",
    "multiEv.chargingPile": "充電樁",
    "multiEv.totalNumber": "總充電樁數",
    "multiEv.totalPower": "充電樁總功率",
    "multiEv.busiestPeriod": "充電樁最繁忙時段",
    "multiEv.seriesCharging": "排隊模式",
    "multiEv.status": "狀態",
    "multiEv.online": "在線",
    "multiEv.type": "類型",
    "multiEv.power": "功率",
    "multiEv.charged": "已充",
    "multiEv.minute": "分鐘",
    "multiEv.used": "已用",
    "multiEv.meter": "電錶",
    "multiEv.realtimeTotalPower": "實時總功率",
    "multiEv.realtimeTotalVoltage": "實時總電壓",
    "multiEv.realtimeTotalCurrent": "實時總電流",
    "multiEv.current": "電流",
    "multiEv.switchedToCharging": "已切換為充電中！",
    "multiEv.chargingHasStopped": "已停止充電！",
    "multiEv.moreDay": "比昨日多xxxx",
    "multiEv.lessDay": "比昨日少xxxx",
    "multiEv.moreHour": "比上一小時多xxxx",
    "multiEv.lessHour": "比上一小時少xxxx",
    "multiEv.unitPrice":"¥", 
    "multiEv.evUnit": "台",
    "multiEv.numberUnit": '次',
    "multiEv.home.tips1": '沒有獲取到充電樁編號，無法重命名',
    "multiEv.home.tips2": '請輸入充電樁名稱',
    "multiEv.home.tips3": '充電樁名稱修改成功',
    "multiEv.home.tips4": '充電樁名字oldName已經改為evName！',
    "multiEv.home.tips5": '充電樁名稱長度最多支持30個字符',
    "multiEv.home.tips6": '修改充電樁名稱',
    "multiEv.home.serialNumber": '序列號',

    // 圖表頁面
    "multiEv.energyStatistics": "數據統計",
    "multiEv.hourlyUsageStatistics": "每小時使用數量統計",
    "multiEv.usageRateStatistics": "每台使用率統計",
    "multiEv.history.tips1": '必須保持選擇一個充電樁圖表數據才能展示！',
    "multiEv.history.tips2": '已添加設備',

    // 設置頁面
    "multiEv.terminal": "終端",
    "multiEv.terminalName": "終端名稱",
    "multiEv.allowGridCharging": "是否允許電網充電",
    "multiEv.modeSelection": "模式選擇",
    "multiEv.evChargers": "充電樁",
    "multiEv.pleaseSelectChargingMode": "請選擇充電模式",
    "multiEv.balancedMode": "平均模式",
    "multiEv.seriesMode": "排隊模式",
    "multiEv.vipMode": "VIP模式",
    "multiEv.customMode": "自定義模式",
    "multiEv.maxChargingCurrentSetting": "最大充電電流設置",
    "multiEv.chargingProportion": "充電占比",
    "multiEv.MaxChargingCurrent": "最大充電電流",
    "multiEv.brand": "品牌",
    "multiEv.serialNumber": "序列號",
    "multiEv.gridTips1": "可以參考所在國的國網電力公司查詢收費標準以及填寫。",
    "multiEv.gridtips2": "您的公用事業能源價格根據能源供應和需求全天有所不同。",
    "multiEv.peakPrice": "高電價",
    "multiEv.flatPrice": "平電價",
    "multiEv.valleyPrice": "低電價",
    "multi.maximumInflowCurrent": "最大流入電流",
};

	
	
	
	
	