// 【公共組件】編輯用戶信息
export default {
    'editUserInfo.editUserInformation': '編輯用戶信息',
    'editUserInfo.modifyUsername': '修改用戶名',
    'editUserInfo.changePictures': '修改頭像',
    'editUserInfo.savePicturesSuccess': '用戶頭像保存成功',
    'editUserInfo.saveError': '保存失敗',
    'editUserInfo.pictureLimit':'圖片必須小於2M',
    'editUserInfo.uploadPicture':'上傳頭像',
    'editUserInfo.picturePreview':'預覽',
    
    'editUserInfo.bindPhoneNumber': '綁定電話號碼',
    'editUserInfo.bindEmail': '郵箱綁定',
    'editUserInfo.changePassword': '密碼修改',

    'editUserInfo.notificationSettings': '通知設置',
    'editUserInfo.editAccount': '編輯用戶賬號信息',
    'editUserInfo.waitBingting': '正在綁定中，請稍候...',
    'editUserInfo.collapse': '收起',
    'editUserInfo.modifyBinding': '修改綁定',
    
    'editUserInfo.emailAddress': '郵箱地址',
    'editUserInfo.tips1': '郵箱綁定成功',
    'editUserInfo.tips2': '保存失敗',
    'editUserInfo.tips3': '正在發送郵箱驗證碼，請稍候...',
    'editUserInfo.tips4': '驗證碼已經發送到郵箱xxxx，請查收',
    'editUserInfo.tips5': '驗證碼發送失敗！',
    'editUserInfo.tips6': '已經綁定手機號',
    'editUserInfo.tips7': '已經綁定郵箱',
    'editUserInfo.emailTips1': '請輸入郵箱地址',
    'editUserInfo.emailTips2': '郵箱地址格式不正確',
    'editUserInfo.emailTips3': 'xxxxs後可重發',
    'editUserInfo.emailTips4': '發送驗證碼',
    'editUserInfo.emailTips5': '郵箱驗證碼不能為空',
    'editUserInfo.emailTips6': '郵箱驗證碼不正確',
    'editUserInfo.emailCode': '郵箱驗證碼',
    'editUserInfo.save': '保存',

    'editUserInfo.phoneTips1': '手機號碼綁定成功',
    'editUserInfo.phoneTips2': '正在發送手機驗證碼，請稍候...',
    'editUserInfo.phoneTips3': '短信驗證碼已經發送到手機xxxx，請查收',
    'editUserInfo.phoneTips4': '短信驗證碼發送失敗！',
    'editUserInfo.phoneTips5': '請輸入電話號碼',
    'editUserInfo.phoneTips6': '電話號碼格式不正確',
    'editUserInfo.phoneTips7': '發送短信驗證碼',
    'editUserInfo.phoneTips8': '短信驗證碼',
    'editUserInfo.phoneTips9': '手機驗證碼不能為空',
    'editUserInfo.phoneTips10': '手機驗證碼不正確',
    'editUserInfo.phone': '電話號碼',


    'editUserInfo.noticeTips1': '獲取通知配置失敗！',
    'editUserInfo.noticeTips2': '通知設置保存成功!',
    'editUserInfo.noticeTips3': '電池電量低通知',
    'editUserInfo.noticeTips4': '充電樁完成通電通知',
    'editUserInfo.errorNotification': '報錯通知',
    'editUserInfo.updateNotification': '升級更新通知',
    'editUserInfo.monthlyStatisticsNotification': '月度統計通知',
    'editUserInfo.notificationMethod': '接收方式',

    'editUserInfo.userNameTips1': '用戶名保存成功',
    'editUserInfo.userNameTips2': '用戶名保存失敗',
    'editUserInfo.userNameTips3': '請輸入用戶名',
    'editUserInfo.userNameTips4': '用戶名格式不正確，必須數字下劃線英文組成',
    'editUserInfo.userNameTips5': '請輸入用戶名',
    'editUserInfo.userNameTips6': '正在保存，請稍候...',
    'editUserInfo.userName': '用戶名',

    'editUserInfo.avatorTips': '點擊修改頭像',
    'editUserInfo.verifyCodeTips1': '請輸入您收到的驗證碼：',
    'editUserInfo.verifyCodeTips2': '為了您的賬號安全，請點擊以下按鈕給原手機號發送驗證碼：',
    'editUserInfo.verifyCodeTips3': '發送驗證碼',
    'editUserInfo.verifyCodeTips4': '原手機驗證通過，請輸入新的綁定手機號',
    'editUserInfo.verifyCodeTips5': '為了您的賬號安全，請點擊以下按鈕給原郵箱發送驗證碼：',
    'editUserInfo.verifyCodeTips6': '原郵箱驗證通過，請輸入新的郵箱',
    'editUserInfo.verifyCodeTips7': '舊密碼',
    'editUserInfo.verifyCodeTips8': '請先綁定郵箱！',
    'editUserInfo.verifyCodeTips9': '驗證碼已發送到郵箱, 請去郵箱查收',
    "editUserInfo.verifyCodeTips10": '，將自動跳轉到登錄頁面',
}
 