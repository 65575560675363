import { getToken } from "./auth";
import { EMS_APP_CLIENT_ID } from '@/tools/env'

interface SSEOptions {
  url: string;
  callback: (type: string, event: any) => void;
}
export const initSSE = ({ url, callback } : SSEOptions) => {
    url = `${url}?Authorization=Bearer ${getToken()}&clientid=${EMS_APP_CLIENT_ID}`;
    const eventSource = new EventSource(url, { 
        withCredentials: true,
    });

    // 监听打开事件
    eventSource.onopen = (event) => {
        callback('OPEN', event);
        console.log('打开SSE连接 onopen==>', event);
    };

    // 监听消息事件
    eventSource.onmessage = (event) => {
        callback('MESSAGE', JSON.parse(event.data));
    };

    // 监听错误事件
    eventSource.onerror = function(event) {
        callback('ERROR', event);
        eventSource.close(); // 可以选择在发生错误时关闭连接
    };

    // 监听结束
    eventSource.addEventListener('end', function(event) {
        callback('END', event);
        eventSource.close();
    }); 
}