// 【公共组件】用户协议
export default {
    'userAgreement.userNotice': '用户须知',
    'userAgreement.privacyPolicy': '隐私政策',
    'userAgreement.tips1': '欢迎您选择深圳旭衡电子有限公司（下文简称“旭衡”）软件服务。我们将按照本隐私政策来处理您的个人信息并保障您信息的安全。',
    'userAgreement.tips2': '旭衡一直专注从事“物联网 + 新能源”的技术研究、产品研发及行业应用的高新技术企业，竭力为用户打造这一高价值。您使用本软件即表示您同意旭衡按照本隐私政策收集、使用、存储及分享您的个人信息。我们也可能会不时更新本隐私政策。',
    'userAgreement.tips3': '一、如何收集和使用您的个人数据',
    'userAgreement.tips3_1': '个人数据是指单独使用或结合其他信息使用时能够确认个人身份的信息。此类数据会在您使用我们产品或服务时被收集。',
    'userAgreement.tips4': '二、我们如何使用Cookie和类似技术 ',
    'userAgreement.tips4_1': '为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。',
    'userAgreement.tips5': '三、旭衡如何披露您的个人数据',
    'userAgreement.tips5_1': '旭衡会在以下情况下，公开披露您的个人信息：',
    'userAgreement.tips5_2': '基于您的明确同意。',
    'userAgreement.tips5_3': '根据法律法规、诉讼或仲裁需要，或按行政或司法机关要求披露。',
    'userAgreement.tips6': '四、旭衡如何保护您的个人数据',
    'userAgreement.tips6_1': '我们非常重视您个人数据的安全。为防止您的个人数据被未经授权的访问、披露或丢失，我们已采取并将继续采取各种措施。',
    'userAgreement.tips7': '五、本政策如何更新',
    'userAgreement.tips7_1': '我们可能会不时更新本隐私政策。 ',
    'userAgreement.tips7_2': '当本政策有重大变化时，我们将通过适当的方式通知您。',
    'userAgreement.contactUs': '联系我们',
    'userAgreement.contactUsTips': '如果您有任何疑问或意见，请通过以下方式联系我们： ',
    'userAgreement.email': '邮箱',
    'userAgreement.contactPhone': '联系电话',
    'userAgreement.contactAddress': '联系地址',
    'userAgreement.addressDetails': '深圳市宝安区航城街道三围社区泰华梧桐工业园大暑(7#)栋4层',
    'userAgreement.company': '深圳旭衡电子科技有限公司',
}
 