export default {
  "mine.other": "Other device management",
  "mine.manage":"Management",
  "mine.searchResult":"Search results",
  "mine.confirm":"I confirm",
  "mine.deleteSuccess":"Deleted successfully",
  "mine.deleteConfirm": 'Are you sure you want to delete [] device?',
  "mine.deviceSerialNumber":"Device serial number",
  "mine.deleteDevice": "Delete device",
  "mine.searchPlaceholder": "Please enter content",

  "mine.userSetting": "User Settings",
  "mine.terminalSetting": "Terminal Basic Settings",
  "mine.uploadCloud": "Data Cloud Backup",
  "mine.changeVersion": "Check for Updates",
  "mine.language": "Switch Language",
  "mine.about": "About",
  "mine.feedback": "Comments and Feedback",
  "mine.logout": "Logout",
  "mine.logoutAccount": "Logout Account",

  "mine.logoutAccountTips": "Cancel the account named []",
  "mine.logoutAccountTips1": "Cancel the account bound to []",
  "mine.logoutAccountCare": "Application for cancellation related matters:",
  "mine.logoutAccountCare1": "1. The account is in a safe state",
  "mine.logoutAccountCare1-1": "The account is currently in normal use and there is no risk of theft, violation, or disablement.",
  "mine.logoutAccountCare2": "2. Account authorization and binding relationship has been released",
  "mine.logoutAccountCare2-1": "The account has been authorized with other third-party platforms, etc.",
  "mine.logoutAccountCare3": "3. The account cannot be retrieved after cancellation, and all user data will be cleared",
  "mine.logoutAccountCare3-1": "After the account is cancelled, the bound mobile phone, email and other accounts and related data will be cleared and cannot be recovered.",
  "mine.confirmLogout": "Confirm cancellation",
  "mine.logoutSuccess": "Account deleted successfully!",

  "mine.aboutAlwaysControl": "About Xuheng",
  "mine.aboutAlwaysControlDesc1": "Xuheng Electronics (Shenzhen) Co., Ltd. was established in 2021. Our team has more than 20 years of technical experience in the fields of uninterruptible power supply, photovoltaics, and charging piles, and has a mature and stable product development platform.",
  "mine.aboutAlwaysControlDesc2": "Always control will provide you with a complete multi-scenario comprehensive product solution, suitable for home/community, enterprise/park, operator, emergency road rescue of new energy vehicles, etc., and equipped with self-developed energy management system (Neuron) and cloud platform, for intelligent energy scheduling between microgrids, real-time monitoring and maintenance, remote operation strategy update, to achieve convenient use, intelligent management, and maximize user benefits.",
  "mine.contactInformation": "Contact information",
  "mine.contactAdress": "Contact address",
  "mine.contactAdressDetails": "401, Building 7, Taihua Wutong Industrial Park, Hangcheng Street, Bao'an District, Shenzhen Xuheng Electronics (Shenzhen) Co., Ltd.",
  "mine.contactPhone": "Contact number",
  "mine.contactEmail": "Email",

  "mine.feedbackIssues": "Feedback issues",
  "mine.feedQuantity": "Quantity",
  "mine.feedAmount": "Maximum 9 images, maximum 1MB/image",

  "mine.notice.fail": "Failed to get notification configuration!",
  "mine.notice.saveSuccess": "Notification settings saved successfully!",
  "mine.notice.saveFail": "Save failed!",
  "mine.notice.title": "Notification settings",
  "mine.notice.batteryLow": "Battery low notification",
  "mine.notice.chargingFinish": "Charging pile charging completed notification",
  "mine.notice.error": "Error notification",
  "mine.notice.update": "Upgrade update notification",
  "mine.notice.monthly": "Monthly statistics notification",
  "mine.notice.receiveType": "Receiving method",

  "mine.language.welcome": "Welcome to AlwaysControl",
  "mine.language.desc1": "Please set the language version according to your region",
  "mine.language.desc2": "You can change it at any time through the application settings",


  "mine.userInfo.avatar":"Avatar",
  "mine.userInfo.nickname":"Nickname",
  "mine.userInfo.password":"Password",
  "mine.userInfo.changePassword":"Change password",
  "mine.userInfo.setPassword":"Set password",
  "mine.userInfo.phoneNumber":"Phone number",
  "mine.userInfo.bindPhone":"Bind phone number",
  "mine.userInfo.bindEmail":"Bind email",
  "mine.loginExpired":"Login expired, please log in again",
  "mine.userInfo.title":"User information",
  "mine.userInfo.bind":"Bind",
  "mine.userInfo.modify":"Modify",
  "mine.userInfo.modifyNickname":"Modify nickname",
  "mine.userInfo.userNickname":"User nickname",
  "mine.userInfo.selectPic":"Please select the uploaded picture",
  "mine.userInfo.saveSuccess":"Save successfully",
  "mine.userInfo.uploadFail":"Upload failed",
  "mine.userInfo.avatarSaveSuccess":"Avatar saved successfully",
  "mine.userInfo.nameTips1":"User nickname cannot be empty",
  "mine.userInfo.nameTips2":"Please enter user nickname"
};
  