// 【公共组件】 登录、忘记密码、注册
export default {
    'account.forgetThePassword': '忘记密码',
    'account.logInDirectly': '直接去登录',
    'account.tips1': '页面正在跳转，请稍候...',
    'account.tips2': '请先查看并同意用户须知！',
    'account.autoLogin': '自动登录',
    'account.userLogin': '用户登录',
    'account.registerTips': '还没有账号？点击这里去注册',
    'account.userEmail': '用户名/邮箱',
    'account.userEmailTips': '请输入用户名/邮箱',
    'account.password': '密码',
    'account.passwordTips': '请输入密码',
    'account.submit': '提交',
    'account.submitTips': '提交中,请稍候...',
    'account.otherLoginTips': '或选择其他登录方式',
    'account.userRegistration': '用户注册',
    'account.userName': '用户名',
    'account.userNameTips': '请输入用户名',
    'account.emailTips1': '请输入邮箱地址',
    'account.emailTips2': '邮箱地址格式不正确',
    'account.phone': '电话号码',
    'account.phoneTips1': '请输入电话号码',
    'account.phoneTips2': '电话号码不正确',
    'account.phoneTips3': '请输入电话号码',
    'account.passwordTips1': '请输入6-30位密码',
    'account.passwordTips2': '密码格式不对，密码由英文数字和下划线横线点号自由组成, 长度6-12位',
    'account.passwordTips3': '两次输入密码不一致，请检查并重新输入！',
    'account.passwordTips4': '请输入密码, 密码由英文数字和下划线横线点号自由组成',
    'account.passwordTips5': '确认密码',
    'account.passwordTips6': '请输入确认密码',
    'account.passwordTips7': '两次输入密码不一致，请检查并重新输入！',
    'account.passwordTips8': '请输入确认密码',
    'account.passwordTips9': '恭喜您注册成功！',
    'account.passwordTips10': '您的用户名是：',
    'account.emailIs': '邮箱是：',
    'account.goToLoginPage': 's后将自动跳转到登录页面',
    'account.goLogin': '去登录',
    'account.goRegister': '注册',
    'account.loginSuccess': '登录成功',
    'account.email': '邮箱',
    'account.sologn': '用心打造专属于您的能源方案',
    'account.pleaseAgree': '我已阅读并同意',
    'account.userAgreement': '《用户协议》',

    "account.code": "验证码",
    "account.codeTips": "请输入验证码",
    "account.codeTips2": "验证码不正确",

    "account.modifyPasswordByAccount": "账号修改密码",
    "account.modifyPasswordByEmail": "邮箱修改密码",
    "account.getPasswordByAccount": "账号找回密码",
    "account.getPasswordByEmail": "邮箱找回密码",

    "account.sendEmailTips": "验证码已发送到邮箱, 请去邮箱查收",
    "account.name": "账号名",
    "account.newPassword": "新密码",
    "account.confirmNewPassword": "确认新密码",
    "account.nameTips": "请输入账号名",
    "account.passwordModifySuccess": "密码修改成功",
    "account.emailCode": "邮箱验证码",
    "account.emailCodeTips": "请输入邮箱验证码",
    "account.emailCodeTips2": "邮箱验证码不正确",
    "account.passworTips1": "您密码已重置成功！",
    "account.passwordTips11": "请输入确认密码",
    "account.passwordTips12": "请输入新密码",
    "account.nickname": "用户昵称",
    "account.modifyNickname": "修改用户昵称",
    "account.modifyNicknameSuccess": "用户昵称修改成功!",
    "account.nicknameTips": "请输入用户昵称",
    'account.nicknameError': '用户昵称修改失败！',

   "account.login": '登入',
    'account.quickRegister': '快速注册',
    'account.sendCode': '发送验证码',
    'account.sendTips': 's后可重发',
    "account.sendEmailIng": '正在发送邮件，请稍候...',
    'account.emailNotEmpty': '邮箱地址不能为空',
    'account.registerAccount': '注册账号',
    'account.registerWelcome': '欢迎注册 Always Control',
    'account.usernameLimit': '用户名只能以字母开头，且只能包含字母、数字、下划线，长度在5-15位之间'
}
 