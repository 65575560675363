// 多桩
export default {
    // 首页
    "multiEv.todaysData": "Today‘s data",
    "multiEv.maxPeakCurrent": "Max Peak Current",
    "multiEv.totalElectricityUse": " Total Electricity Use",
    "multiEv.totalElectricityCost": "Total Electricity Cost",
    "multiEv.totalChargingTimes": "Total Charging Times",
    "multiEv.hourlyData": "Hourly data",
    "multiEv.normal": "Normal",
    "multiEv.exception": "Exception",
    "multiEv.unknown": 'Unknown',
    "multiEv.idle": "Idle",
    "multiEv.charging": "Charging",
    "multiEv.offline": "Offline",
    "multiEv.liveData": "Live Data",
    "multiEv.chargingPile": "Charging Pile",
    "multiEv.totalNumber": "Total Number",
    "multiEv.totalPower": "Total Power",
    "multiEv.busiestPeriod": "Busiest Period",
    "multiEv.seriesCharging": "series charging",
    "multiEv.status": "Status",
    "multiEv.online": "Online",
    "multiEv.type": "Type",
    "multiEv.power": "Power",
    "multiEv.charged": "Charged",
    "multiEv.minute": "Minute",
    "multiEv.used": "Used",
    "multiEv.meter": "Meter",
    "multiEv.realtimeTotalPower": "Real-time Total Power",
    "multiEv.realtimeTotalVoltage": "Real-time Total Voltage",
    "multiEv.realtimeTotalCurrent": "Real-time Total Current",
    "multiEv.current": "Current",
    "multiEv.switchedToCharging": "Switched to charging!",
    "multiEv.chargingHasStopped": "Charging has stopped!",
    "multiEv.moreDay": "more xxxx than yesterday",
    "multiEv.lessDay": "less xxxx than yesterday",
    "multiEv.moreHour":"more xxxx than last hour",
    "multiEv.lessHour":"less xxxx than last hour",
    "multiEv.unitPrice":"$", 
    "multiEv.evUnit": "tower",
    "multiEv.numberUnit": 'number',
    "multiEv.home.tips1": 'The charging pile number was not obtained and cannot be renamed.',
    "multiEv.home.tips2": 'Please enter the name of the charging station',
    "multiEv.home.tips3": 'The charging pile name was modified successfully',
    "multiEv.home.tips4": 'The charging station name oldName has been changed to evName!',
    "multiEv.home.serialNumber": 'Serial number',
    "multiEv.home.tips5": 'The charging station name can be up to 30 characters in length.',
    "multiEv.home.tips6": 'Modify the charging station name',
    
    // 图表页面
    "multiEv.energyStatistics": "Energy statistics",
    "multiEv.hourlyUsageStatistics": "Hourly usage statistics",
    "multiEv.usageRateStatistics": "Usage rate statistics",
    "multiEv.history.tips1": 'You must keep selecting a charging station chart data to display!',
    "multiEv.history.tips2": 'Added Device',

    // 设置页面
    "multiEv.terminal": "Terminal",
    "multiEv.terminalName": "Terminal Name",
    "multiEv.allowGridCharging": "Allow Grid Charging",
    "multiEv.modeSelection": "Mode Selection",
    "multiEv.evChargers": "EV chargers",
    "multiEv.pleaseSelectChargingMode": "Please Select Charging Mode",
    "multiEv.balancedMode": "Balanced mode",
    "multiEv.seriesMode": "Series mode",
    "multiEv.vipMode": "VIP mode",
    "multiEv.customMode": "Custom mode",
    "multiEv.maxChargingCurrentSetting": "Max charging current setting",
    "multiEv.chargingProportion": "Charging proportion",
    "multiEv.MaxChargingCurrent": "Max charging current",
    "multiEv.brand": "brand",
    "multiEv.serialNumber": "serial number",
    "multiEv.gridTips1": "You can refer to the National Power Grid Company of your country to inquire about the charging standards and fill them in.",
    "multiEv.gridtips2": "The price of your utility energy varies throughout the day based on energy supply and demand.",
    "multiEv.peakPrice": "peak price",
    "multiEv.flatPrice": "flat price",
    "multiEv.valleyPrice": "valley price",
    "multi.maximumInflowCurrent": "Maximum inflow current",
};

	
	
	
	
	