export default {
  "common.mode.auto": "Self-use",
  "common.mode.economy": "Economic",
  "common.mode.exigency": "Emergency",

  // 设备
  "common.grid": "Grid",
  'common.evChargers': 'EV Chargers',
  'common.pv': 'PV',
  'common.inverter': 'Inverter',
  'common.pcs': 'Inverter + Battery',
  'common.terminal': 'Terminal Equipment',
  'common.airc': 'Air Conditioner',
  'common.engine': 'Engine',
  'common.battery': 'Battery',
  'common.metter': 'Metter',
  'common.other': 'Other',
  "common.power": "Power",
  "common.powerTotal": "Total power",
  "common.voltage": "Voltage",
  "common.electricity": "Electricity",
  "common.current": "Current",
  "common.homeUsage": "homeUsage",

  // 数据
  "common.noDataAvailable": 'No data available',
  'common.tips': 'Tips',
  "common.reset": "Reset",
  "common.currencyUnit": '$',
  "common.submiting": 'Submitting, please wait...',
  "common.close": 'close',
  "common.from": 'from',
  "common.useIn": 'used for',
  "common.energyPercent": 'Proportion of energy',
  "common.dataFlow": "Data flow",
  "common.getDataIng": 'Retrieving data, please wait...',
  "common.pageLoading": 'Page content is being loaded, please wait ...',
  "common.reseting": "Page content reset, please wait ...",
  "common.save": 'Save',
  "common.onlyNumberTips": "Only numbers can be filled in",
  "common.offline":"Offline",
  "common.online":"Online",
  "common.fault":"Fault",
  "common.charge":"Charge",
  "common.discharge":"Discharge",
  "common.buy":"Buy",
  "common.sell":"Sell",
  "Common.loadinginfo": 'In loading, please wait ...',

  // 
  "common.moreFeatures": "More features",
  "common.confirm": 'Confirm',
  "common.saveing": "Saving, please wait...",
  "common.searchPlacholder": "Please enter your search keyword",
  "common.isee": "I see",
  "common.pageErrorTips1": "Oops, there is an error on the webpage~",
  "common.pageErrorTips2": "Please try refreshing or contact customer service~",
  "common.checkTips": "Verifying, please wait...",
  "common.back": "Back",
  "common.cancel": "Cancel",
  "common.optional": "Optional",
  "common.required": "Required",
  "common.status": "Status",
  "common.statusNoDelete": "Not deleted",
  "common.statusDelete": "Deleted",
  "common.submit": 'Submit',
  "common.disable": "Disable",
  "common.enable": "Enable",
  "common.year": 'Year',
  "common.month": 'Month',
  "common.day": 'Day',
  "common.version": 'Version number:',
  "common.backHome": 'Back to home page',
  "common.backHomeSorry": 'Sorry, the page got lost accidentally',
  "common.requestTips1": 'Data is being processed, please do not submit again',
  "common.requestTips2": "System prompt",
  "common.requestTips3": "Login status has expired, please log in again",
  "common.requestTips4": "Log in again",
  "common.requestTips5": "Invalid session, or session has expired, please log in again.",
  "common.requestTips6": "Backend interface connection abnormality",
  "common.requestTips7": "System interface request timeout",
  "common.requestTips8": 'System interface',
  "common.requestTips9": 'Abnormal',
  "common.iknow": 'I know',
};
