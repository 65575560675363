// 【公共組件】消息通知
export default {
    'notice.title': '標題',
    'notice.time': '時間',
    'notice.type': '類型',
    'notice.success': '標記成功！',
    'notice.deleteSuccess': '刪除成功！',
    'notice.read': '標記已讀',
    'notice.delete': '刪除',
    'notice.allRead': '全部已讀',
    'notice.totalItems': '共xxxx條',
    'notice.tips': '內容正在加載，請稍候...',
    "notice.information": "信息通知",
    "notice.informationCenter": "消息通知中心",
    'notice.allDelete': '全部刪除',
}
 