// 【公共组件】常见问题
export default {
    'question.commonQuestions': '常见问题',
    'question.tips1': '本地与云端有什么区别？',
    'question.tips1-1': '本地有限制功能，云端支持更强大更多例如：远程操控以及ota升级、以及天气预警功能等。如果没有开通云端功能，部分功能会被限制展示，选择本地和云端版本看到的内容可能不相同。',
    'question.tips2': '为什么要选择云端数据备份？',
    'question.tips2-1': '选择云端数据备份，能节省hems本地的存储，以及可一根据客户的用户习惯做更好的推荐运算，也可以帮助客户调整最适合自己的模式。',
    'question.tips-3': '切换模式之后为什么没有看到很大的效果？',
    'question.tips3-1': '能量管理需要一段时间才能有明显的效果，建议对比一周以上的数据。',
    'question.tips4': '配网或者添加设备时，找不到要连接的路由器?',
    'question.tips4-1': '*请确保当前路由器已设置密码。',
    'question.tips4-2': '*请确保当前路由器不需要二次身份与密码验证。',
    'question.tips4-3': '*不支持5GWi-Fi网络连接，请调整到2.4GWi-Fi网络连接',
    'question.tips4-4': '*请确保路由器未设置隐藏ssid。已设置隐藏ssid的路由器无法在列表中显示，请修改路由器设置后重新尝试连接。',
    'question.tips4-5': '上述方法不能解决，建议重启路由器或者hems尝试重新连接。',
    'question.tips5': '出现“设备离线”提示怎么办?',
    'question.tips5-1': '当出现“设备离线”提示时，可能因为当前网络环境较差或设备断电导致。',
    'question.tips5-2': '请尝试以下操作:',
    'question.tips5-3': '*设备是否连接电源;',
    'question.tips5-4': '*路由器是否能连接外网;',
    'question.tips5-5': '*尝试断电重启设备;',
    'question.tips5-6': '*如果路由器更换密码，请尝试重置设备。',
    'question.tips6': '更改密码或更换路由器后无法使用原有设备怎么办?',
    'question.tips6-1': '若更改密码或更换路由器后需要将设备接通电源后重新配网，并重新选择路由器热点、输入密码重新连接。点击我的-配网设置，重新进入配网流程。',
    'question.tips7': '如何添加新设备？',
    'question.tips7-1': '请切换成本地版本进行添加设备的操作，当全新的设备通电后，点击我的，选择对应设备的分类，之后选择添加设备。进行添加设备流程。',
    'question.tips8': '扫描不到添加设备怎么办？',
    'question.tips8-1': '可能当前不是在本地版本或者信号有被干扰或者设备已经被绑定，请尝试重新连接。',
}
 