// 【公共組件】設備管理
export default {
   'device.deviceId': '設備編號',
   'device.terminalNumber': '終端編號',
   'device.deviceMacAddress': '設備mac地址',
   'device.specification': '詳細參數',
   'device.detailedParameters': '設備詳細參數',
   'device.tips1': '請輸入設備名稱、設備編號或終端編號',
   'device.search': '搜索',
   'device.tips2': '搜索到以下設備',
   'device.tips3': '設置成功！',
   'device.tips4': '設備xxxx解綁成功！',
   'device.normal': '正常',
   'device.offline': '離線',
   'device.abnormal': '異常',
   'device.turnedOn': '已開啟',
   'device.unopened': '未開啟',
   'device.delete': '解綁',
   'device.confirmDelete': '確認解綁',
   'device.tips5': '請確認是否解綁設備？',
   'device.neuronErrorTips1': '解綁失敗！',
   'device.neuronErrorTips2': '請檢查Neuron是否接通電源和網絡是否正常後重試。',
   'device.neuronSuccessTips1': '解綁成功！',
   'device.neuronSuccessTips2': 'Neuron解綁後，歷史數據將不能訪問。',
   'device.neuronSuccessTips3': '如需要查閱歷史數據，需重新綁定Neuron後導入歷史數據。',
   'device.neuronUnbinding': 'Neuron解綁中，請稍候….',
   'device.neuronConfirmUnbind': '確認解綁',
   'device.neuronUnbindTips1': '解綁Neuron後，Neuron下掛的設備也將一起解綁。',
   'device.neuronUnbindTips2': '確認要解綁Neuron嗎？',
   'device.neuronUnbindTips3': '解綁Neuron',
   'device.neuronUnbindTips4': '一鍵解綁所有下掛設備',
   'device.neuronNumber': 'Neuron編號',
   'device.tips6': '您還沒綁定設備，',
   'device.tips7': '去綁定',
   'device.tips8': '確認要解綁Neuron編號為xxx的所有下掛設備嗎？',
   'device.tips9': '由於您沒有綁定Neuron, 無法訪問Neuron所管理的數據, 是否跳轉綁定Neuron頁面？',
   'device.tips10': '合成設備',
   'device.status': '設備狀態',
   'device.unbind': '解綁',
   'device.deviceName': '設備名稱',
   'device.childDevice': '子設備',
}
