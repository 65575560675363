// 【公共組件】綁定終端
export default {
    'bindTerminal.tips1': '終端信息校驗中，請稍候...',
    'bindTerminal.tips2': '請進行認證',
    'bindTerminal.tips3': '請輸入終端所在地',
    'bindTerminal.tips4': '請選擇終端序列號',
    'bindTerminal.tips5': '請填寫終端序列號',
    'bindTerminal.terminalSerialNumber': '終端序列號',
    'bindTerminal.terminalLocation': '終端所在地',
    'bindTerminal.success': '終端綁定成功!',
    'bindTerminal.active': '終端激活認證',
    'bindTerminal.regText': '終端序列號不能為空',
    'bindTerminal.errorText': '終端序列號不正確',
    'bindTerminal.area': '國家地區',
    'bindTerminal.areaError': '國家地區不能為空',
    'bindTerminal.areaPlaceholder': '請選擇國家地區',
    'bindTerminal.tips6': '是否導入歷史數據',
    'bindTerminal.tips7': '檢測到您之前綁定過Neuron， 請選擇是否需要同步歷史數據到當前綁定的Neuron中。如不同步，將無法查看之前綁定Neuron的歷史數據',
    'bindTerminal.tips8': '不同步歷史數據',
}
