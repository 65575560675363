// 【公共元件】使用者協議
export default {
    'userAgreement.userNotice': 'User Notice',
    'userAgreement.privacyPolicy': 'Privacy Policy',
    'userAgreement.tips1': 'Welcome to use the software provided by Shenzhen Always-control Technology Co.,Ltd. (hereinafter referred to as "Always-control"). We will protect and secure your personal information in accordance with this privacy policy.',
    'userAgreement.tips2': 'Always-control has always been committed to the research and application of "Internet of Things + New Energy" technology, product research and development, and providing users with high-quality technical services in various industries. By using this software, you agree that Always-control will collect, use, store, and share your personal information. We may also update this privacy policy as needed.',
    'userAgreement.tips3': 'I. How we collect and use your personal data',
    'userAgreement.tips3_1': "Personal data refers to information that can identify an individual's identity alone or in combination with other information. The personal data we collect depends on the products and services you use and how you interact with us. ",
    'userAgreement.tips4': 'II. How we use cookies and similar technologies',
    'userAgreement.tips4_1': 'To ensure the normal operation of the website, we will store a small data file called a cookie on your computer or mobile device.',
    'userAgreement.tips5': 'III. How Always-control shares your personal data',
    'userAgreement.tips5_1': 'Always-control will disclose your personal data in the following circumstances:',
    'userAgreement.tips5_2': 'Based on your explicit consent.',
    'userAgreement.tips5_3': 'As required by laws and regulations, litigation or arbitration, or as requested by administrative or judicial authorities.',
    'userAgreement.tips6': 'IV. How Alwayscontrol protects your personal data',
    'userAgreement.tips6_1': 'We take the security of your personal data very seriously. To prevent unauthorized access, disclosure, or loss of your personal data, we have taken and will continue to take various measures.',
    'userAgreement.tips7': ' V. How this policy is updated',
    'userAgreement.tips7_1': 'We may update this privacy policy from time to time. ',
    'userAgreement.tips7_2': 'When there are significant changes to the policy, we will notify you through appropriate means.',
    'userAgreement.contactUs': 'Contact Us',
    'userAgreement.contactUsTips': ' If you have any questions or concerns, please contact us through the following ways:',
    'userAgreement.email': 'Email:',
    'userAgreement.contactPhone': 'Contact number:',
    'userAgreement.contactAddress': 'Address: ',
    'userAgreement.addressDetails': "Always-control Technology Co.,Ltd.401, Building 7, Taihua Wutong IndustrialPark, Hangcheng Street, Bao 'an District,Shenzhen,China",
    'userAgreement.company': 'Shenzhen Xuheng Electronic Technology Co., Ltd.'
   }