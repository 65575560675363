import request from "@/tools/request";

// 更新数据上云
export async function updateCloudStatus(options: { [key: string]: any }) {
    return request('/updateCloudStatus', {
        method: "POST",
        data: options || {},
    })
}
// 消息通知-批量删除
export async function messageRemove(options: { [key: string]: any }) {
    return request(`/messageDetails/removeByIds`, {
        method: "DELETE",
        data: options || {},
    })
}

// 消息通知-批量已读
export async function messageUpdateRead(options: { [key: string]: any }) {
    return request(`/messageDetails/removeByIds`, {
        method: "PUT",
        data: options || {},
    })
}


// 消息通知-保存推送设置
export async function noticeSettingSave(options: { [key: string]: any }) {
    return request(`/noticeSetting/save`, {
        method: "POST",
        data: options || {},
    })
}

// 消息通知-修改推送设置
export async function noticeSettingUpdate(options: { [key: string]: any }) {
    return request(`/noticeSetting/update`, {
        method: "POST",
        data: options || {},
    })
}
// 消息通知-查询推送设置
export async function getNoticeSetting(options: { [key: string]: any }) {
    return request(`/noticeSetting/getNoticeSetting`, {
        method: "POST",
        data: options || {},
    })
}

// 消息通知-通知列表
export async function getMessageList(options: { [key: string]: any }) {
    return request(`/messageDetails/list`, {
        method: "GET",
        params: options,
    })
}


// 设备管理-设备列表(Neuron下挂设备)
export async function getUserDeviceList(options?: { [key: string]: any }) {
    return request('/user/device/upper/infos', {
        method: "GET",
        params: options || {},
    })
}

// 设备管理-删除设备
export async function deleteDevice(deviceSn: string) {
    return request(`/user/device/socure/${deviceSn}`, {
        method: "GET",
    })
}

// 设备管理-设备启停
export async function deviceStartAndStop(options: { [key: string]: any }) {
    return request(`/userDevice/update`, {
        method: "PUT",
        data: options || {},
    })
}

// 设备管理-操作设备
export async function deviceOperation(options: { terminalSn: string; operationDeviceSns: string[]; operation: 0 | 1 | 2 }) {
    return request(`/iot/ems/operation`, {
        method: "POST",
        data: options || {},
    })
}

// 关闭新手指引
export async function closeUserGuide(options?: { [key: string]: any }) {
    return request(`/closeGuide`, {
        method: "POST",
        data: options || {},
    })
}
// 消息通知
export async function getNoticeMessageList(options?: { [key: string]: any }) {
    return request(`/system/message/notice/list`, {
        method: "GET",
        params: options || {},
    })
}
// 删除消息
export async function deleteNoticeMessage(options: { messageIds?: number[], isAllDelete?: boolean }) {
    return request(`/system/message/notice/delete`, {
        method: "GET",
        params: options || {},
    })
}
// 一键已读
export async function readAllNoticeMessage(options: { messageIds?: number[], isAllCheck?: boolean }) {
    return request(`/system/message/notice/check`, {
        method: "GET",
        params: options || {},
    })
}


// 绑定设备-云服务下发本地设备搜索
export function useDeviceSearch(params?: {deviceSn?: string}) {
    return request(`/iot/ems/search/${params?.deviceSn}`, {
        method: "GET",
    })
}

// 绑定设备-获得本地搜索设备列表
export function getDeviceSerch(params?: {deviceSn?: string}) {
    return request(`/iot/ems/get/search`, {
        method: "GET",
        params
    })
}

// 绑定设备-获得本地搜索设备列表
export function sendBindDeviceSns(options?: { [key: string]: any }) {
    return request(`/iot/ems/binding/deviceSns`, {
        method: "POST",
        data: options || {},
    })
}



// 上传文件
export function uploadFile(options?: { [key: string]: any }) {
    return request(`/system/user/profile/avatar`, {
        method: "POST",
        data: options || {},
    })
}


// 解绑Neuron下发指令解绑所有子设备
export function sendUnbindNeuronCommand(params?: { [key: string]: any }, options?: { [key: string]: any }) {
    return request(`/iot/ems/unbind/all`, {
        method: "GET",
        params: params || {},
        data: options || {},
    });
}

// 解绑所有设备绑定
export function unbindNeuron(params?: { [key: string]: any }, options?: { [key: string]: any }) {
    return request(`/iot/deviceInfo/unbind`, {
        method: "GET",
        params: params || {},
        data: options || {},
    });
}

// 获取历史绑定过的Neuron记录
export function getNeuronHistoryList(params?: { [key: string]: any }, options?: { [key: string]: any }) {
    return request(`/iot/deviceInfo/get/historicalBindingSns`, {
        method: "GET",
        params: params || {},
        data: options || {},
    });
}

// 绑定Neuron
export function bindNeuron(options?: { [key: string]: any }) {
    return request(`/iot/deviceInfo/binding`, {
        method: "POST",
        data: options || {},
    });
}


// 获取当前用户下所有设备，包括所有Neuron和下挂设备
export function getAllDeviceList(params?: { [key: string]: any }, options?: { [key: string]: any }) {
    return request(`/iot/deviceInfo/list/user`, {
        method: "GET",
        params: params || {},
        data: options || {},
    });
}