import React from 'react'
import * as types from '@/types'
import i18n from 'i18next'
import style from './index.scss'

//错误边界
//https://zh-hans.react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export default class ErrorBoundary extends React.Component<types.PropNameAny, types.PropNameAny> {
  constructor(props: types.PropNameAny) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // 更新状态，以便下一次渲染将显示后备 UI。
    return { hasError: true }
  }

  componentDidCatch(error: any, info: any) {
    console.log('error', error, info)
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <div className={style['error-boundary']}>
          <div>
            {i18n.t('common.pageErrorTips1')}
            <br />
            {i18n.t('common.pageErrorTips2')}
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
