// 【公共组件】基本时间设置
export default {
   'baseSettingTime.tips1': '正在提交中， 请稍候...',
   'baseSettingTime.tips2': '保存成功',
   'baseSettingTime.tips3': '时间设置不能为空',
   'baseSettingTime.tips4': '时区设置不能为空',
   'baseSettingTime.tips5': '请选择时区',
   'baseSettingTime.timeSetting': '时间设置',
   'baseSettingTime.basicTimeSettings': '基本时间设置',
   'baseSettingTime.timeZoneSetting': '时区设置',
   'baseSettingTime.noSearchData': '没有搜索到时区数据数据',
   'baseSettingTime.timePlaceholder': '请选择时间，如：',
   'baseSettingTime.timeZonePlaceholder': '请选择时区，如：'
};
