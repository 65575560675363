// 頁面通用頭部
export default {
  "commonHeader.cloud": "雲端",
  "commonHeader.local": "本地",
  "commonHeader.addDevice": "添加設備",
  "commonHeader.timeSetting": "時間設置",
  "commonHeader.dataBackup": "數據上雲備份",
  "commonHeader.notice": "信息通知",
  "commonHeader.quickStartGuid": "新手教程"
};
