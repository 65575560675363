import { createSlice, createAction } from '@reduxjs/toolkit'
import { getCurrentDateTimeInfo } from '@/tools/time'
import * as types from '@/types'

export const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState: {
    historyData: {
      currentAbilityTabName: 'energyStatistics', // 当前功能模块
      deviceActiveName: 'HOME', // 当前选中设备
      deviceTotal: null, // 统计信息
      energyFlowData: null, // 能量流占比
      openEyesData: null, // 打开眼睛需要展示的数据
      defaultData: null, // 汇总数据、收益统计数据、绿色减排数据
      isOpenEyes: false, // 是否查看拆分数据
      timeRange: null, // 时间范围
      chartId: '', 
      timeInfo: getCurrentDateTimeInfo(), // 当前选中时间

      socData: null, // soc数据
      socDataTimeRange: null, // soc数据时间轴

      revenueData: null, // 收益统计
      reductionData: null // 绿色减排
    },
  },
  reducers: {
    saveHistoryData: (state: types.PropNameAny, action) => {
      state.historyData = { ...action.payload }
    },
  }
})
// Action creators are generated for each case reducer function


export const { 
  saveHistoryData,
} = dashboardDataSlice.actions

export const getHistoryData = (state: any) => state.dashboardData.historyData


export default dashboardDataSlice.reducer
