// 多桩
export default {
    // 首页
    "multiEv.todaysData": "今日数据",
    "multiEv.maxPeakCurrent": "今日最大峰值电流",
    "multiEv.totalElectricityUse": "今日总用电量",
    "multiEv.totalElectricityCost": "今日用电总费用",
    "multiEv.totalChargingTimes": "今日充电次数",
    "multiEv.hourlyData": "当时数据",
    "multiEv.normal": "正常",
    "multiEv.exception": "异常",
    "multiEv.unknown": '未知',
    "multiEv.idle": "闲余",
    "multiEv.charging": "正在充电",
    "multiEv.offline": "离线",
    "multiEv.liveData": "实时数据",
    "multiEv.chargingPile": "充电桩",
    "multiEv.totalNumber": "总充电桩数",
    "multiEv.totalPower": "充电桩总功率",
    "multiEv.busiestPeriod": "充电桩最繁忙时段",
    "multiEv.seriesCharging": "排队模式",
    "multiEv.status": "状态",
    "multiEv.online": "在线",
    "multiEv.type": "类型",
    "multiEv.power": "功率",
    "multiEv.charged": "已充",
    "multiEv.minute": "分钟",
    "multiEv.used": "已用",
    "multiEv.meter": "电表",
    "multiEv.realtimeTotalPower": "实时总功率",
    "multiEv.realtimeTotalVoltage": "实时总电压",
    "multiEv.realtimeTotalCurrent": "实时总电流",
    "multiEv.current": "电流",
    "multiEv.switchedToCharging": "已切换为充电中！",
    "multiEv.chargingHasStopped": "已停止充电！",
    "multiEv.moreDay": "比昨日多xxxx",
    "multiEv.lessDay": "比昨日少xxxx",
    "multiEv.moreHour": "比上一小时多xxxx",
    "multiEv.lessHour": "比上一小时少xxxx",
    "multiEv.unitPrice":"¥", 
    "multiEv.evUnit": "台",
    "multiEv.numberUnit": '次',
    "multiEv.home.tips1": '没有获取到充电桩编号，无法重命名',
    "multiEv.home.tips2": '请输入充电桩名称',
    "multiEv.home.tips3": '充电桩名称修改成功',
    "multiEv.home.tips4": '充电桩名字oldName已经改为evName！',
    "multiEv.home.tips5": '充电桩名称长度最多支持30个字符',
    "multiEv.home.tips6": '修改充电桩名称',
    "multiEv.home.serialNumber": '序列号',

    // 图表页面
    "multiEv.energyStatistics": "数据统计",
    "multiEv.hourlyUsageStatistics": "每小时使用数量统计",
    "multiEv.usageRateStatistics": "每台使用率统计",
    "multiEv.history.tips1": '必须保持选择一个充电桩图表数据才能展示！',
    "multiEv.history.tips2": '已添加设备',

    // 设置页面
    "multiEv.terminal": "终端",
    "multiEv.terminalName": "终端名称",
    "multiEv.allowGridCharging": "是否允许电网充电",
    "multiEv.modeSelection": "模式选择",
    "multiEv.evChargers": "充电桩",
    "multiEv.pleaseSelectChargingMode": "请选择充电模式",
    "multiEv.balancedMode": "平均模式",
    "multiEv.seriesMode": "排队模式",
    "multiEv.vipMode": "VIP模式",
    "multiEv.customMode": "自定义模式",
    "multiEv.maxChargingCurrentSetting": "最大充电电流设置",
    "multiEv.chargingProportion": "充电占比",
    "multiEv.MaxChargingCurrent": "最大充电电流",
    "multiEv.brand": "品牌",
    "multiEv.serialNumber": "序列号",
    "multiEv.gridTips1": "可以参考所在国的国网电力公司查询收费标准以及填写。",
    "multiEv.gridtips2": "您的公用事业能源价格根据能源供应和需求全天有所不同。",
    "multiEv.peakPrice": "高电价",
    "multiEv.flatPrice": "平电价",
    "multiEv.valleyPrice": "低电价",
    "multi.maximumInflowCurrent": "最大流入电流",
};

	
	
	
	
	