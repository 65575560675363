import React from 'react'
import ReactDOM, { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { Provider } from 'react-redux'
import { store, persistor } from '@/store'
import { PersistGate } from 'redux-persist/lib/integration/react'
import './actions/locales'
import './index.scss'

const rootEle = document.getElementById('root')

if (rootEle) {
  const root = ReactDOM.createRoot(rootEle)
  root.render(
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          {' '}
          <App />
        </BrowserRouter>
    	{/* </PersistGate> */}
    </Provider>
  )
}
