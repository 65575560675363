// 公共组件绑定设备
export default {
    "bindDevice.tips1": 'Please make sure that all devices to be bound are powered on, then click Bind Device to bind the devices.',
    "bindDevice.tips2": "Searching for devices, please wait...",
    "bindDevice.tips3": "You are currently using the cloud version, and adding devices is not possible. Please switch to the local version to add devices!",
    "bindDevice.tips4": "Devices binding successfully！",
    "bindDevice.tips5": "Devices are binding, please wait...",
    "bindDevice.tips6": "Please do not remove the power supply or turn off the device.",
    "bindDevice.tips7": "Search Results",
    "bindDevice.tips8": "No devices found. Please ensure all devices are powered on and try again.",
    "bindDevice.tips9": "Retry",
    "bindDevice.tips10": "Devices are not fully communicating yet.",
    "bindDevice.tips11": "Only some devices were found.",
    "bindDevice.tips12": "Continue waiting for 120s",
    "bindDevice.tips13": "Retry and Bind",
    "bindDevice.tips14": "Please select the devices to bind from the list below:",
    "bindDevice.backToHome": "Back to Home",
    "bindDevice.select": "Select",
    "bindDevice.deviceBrand": "Device Brand",
    "bindDevice.deviceModal": "Device Model",
    "bindDevice.deviceType": "Device Type",
    "bindDevice.deviceMacAddress": "Device MAC Address",
    "bindDevice.selectAll": "Select All",
    "bindDevice.confirm": "Confirm",
    "bindDevice.addDevice": "Add device",
    "bindDevice.searchAgain": "Search again",
    "bindDevice.addDeviceTips": "The device has been bound. Are you sure to add more device binding?",
    "bindDevice.addDeviceConfirm": "Confirm to add binding",
    "bindDevice.gotoBind": "Go to bind device",

    'bindDevice.searchBtn': 'Search device',
    'bindDevice.confirmDevice': 'confirm the device',
    'bindDevice.deviceInit': 'Equipment initialization',
    'bindDevice.text': 'Binding device',
    'bindDevice.selectOperate': 'Please select the following operation',
    'bindDevice.deviceList': 'List of Device',
    'bindDevice.partSuccess': 'The device is only partialized and successful',
};
