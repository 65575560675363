import { createSlice } from '@reduxjs/toolkit'
import * as types from '@/types'

export const homeDataSlice = createSlice({
  name: 'homeData',
  initialState: {
    deviceRealTimeData: null,
    mqttClient: null,
    currentDataSourceFrom: 'offline', // 'offline'/'online'当前数据是默认数据还是后端返回数据
    bindDeviceStatus: 'no', // 绑定设备完成
    isShowBaseTimeSetting: false,  //【本地流程】是否显示时间基础设置
    historyTimeArea: null,
    isNight: false, // 是否是晚上
    dataSourceType: 'Corr', // 数据源类型， 初始数据Init, 修正数据Corr
  },
  reducers: {
    saveDeviceRealTimeData: (state: types.PropNameAny, action) => {
      state.deviceRealTimeData = action.payload
    },
    saveCurrentDataSourceFrom: (state: types.PropNameAny, action) => {
      state.currentDataSourceFrom = action.payload
    },
    setBindDeviceStatus: (state: types.PropNameAny, action) => {
      state.bindDeviceStatus = action.payload
    },
    saveShowBaseTimeSetting: (state: types.PropNameAny, action) => {
      state.isShowBaseTimeSetting = action.payload
    },
    saveHistoryTimeArea: (state: types.PropNameAny, action) => {
      state.historyTimeArea = {...action.payload}
    },
    saveIsNight: (state: types.PropNameAny, action) => {
      state.isNight = action.payload
    },
    saveDefaultSourceType:  (state: types.PropNameAny, action) => {
      state.dataSourceType = action.payload
    },
  }
})

export const { 
  saveCurrentDataSourceFrom, 
  saveDeviceRealTimeData,
  setBindDeviceStatus,
  saveShowBaseTimeSetting,
  saveHistoryTimeArea,
  saveIsNight,
  saveDefaultSourceType
} = homeDataSlice.actions



export default homeDataSlice.reducer
