// 页面通用头部
export default {
  "commonHeader.cloud": "云端",
  "commonHeader.local": "本地",
  "commonHeader.addDevice": "添加设备",
  "commonHeader.timeSetting": "时间设置",
  "commonHeader.dataBackup": "数据上云备份",
  "commonHeader.notice": "信息通知",
  "commonHeader.quickStartGuid": "新手教程"
};
