// 【公共组件】绑定设备
export default {
    "bindDevice.tips1": "请确认需要绑定的设备都已上电后，点击去绑定设备进行设备绑定。",
    "bindDevice.tips2": "正在搜索设备，请稍候...",
    "bindDevice.tips3": "当前使用云端版本，无法添加设备，请切换本地版本进行设备添加！！",
    "bindDevice.tips4": "设备绑定成功！",
    "bindDevice.tips5": "设备绑定中，请稍候...",
    "bindDevice.tips6": "请勿移除电源和关掉设备。",
    "bindDevice.tips7": "搜索设备结果",
    "bindDevice.tips8": "没有搜索到设备，请确认设备都已上电后重试",
    "bindDevice.tips9": "重新搜索",
    "bindDevice.tips10": "设备还未完全通信",
    "bindDevice.tips11": "仅搜索到部分设备",
    "bindDevice.tips12": "继续等待120s",
    "bindDevice.tips13": "重新搜索绑定",
    "bindDevice.tips14": "请根据以下搜索到的设备进行选择绑定",
    "bindDevice.backToHome": "返回主页",
    "bindDevice.select": "选择",
    "bindDevice.deviceBrand": "设备品牌",
    "bindDevice.deviceModal": "设备型号",
    "bindDevice.deviceType": "设备类型",
    "bindDevice.deviceMacAddress": "设备MAC地址",
    "bindDevice.selectAll": "全选",
    "bindDevice.confirm": "确认无误",
    "bindDevice.addDevice": "添加设备",
    "bindDevice.searchAgain": "重新搜索",
    "bindDevice.addDeviceTips": "已经绑定过设备，确认增加设备绑定吗？",
    "bindDevice.addDeviceConfirm": "确认增加绑定",
    "bindDevice.gotoBind": "去绑定设备",

    'bindDevice.searchBtn': '搜索设备',
    'bindDevice.confirmDevice': '确认设备',
    'bindDevice.deviceInit': '设备初始化',
    'bindDevice.text': '绑定设备',
    'bindDevice.selectOperate': '请选择以下操作',
    'bindDevice.deviceList': '设备列表',
    'bindDevice.partSuccess': '设备仅部分初始化成功',
};
