import { Route } from "../types"

export default (lazyLoad: any) => {
  return [
    {
      path: '/home',
      name: '首页',
      element: lazyLoad('Home/template/Hems')
    },
    {
      path: '/',
      name: '首页',
      element: lazyLoad('Home/template/Hems')
    },
    {
      path: '/mine',
      name: '我的',
      children: [
        {
          path: '/mine/mineCenter',
          name: '我的中心',
          element: lazyLoad('Mine/components/MineCenter')
        },
        // {
        //   path: '/mine/deviceManagement',
        //   name: '设备管理',
        //   element: lazyLoad('Mine/components/DeviceManagement')
        // },
        {
          path: '/mine/messageCenter',
          name: '消息中心',
          element: lazyLoad('Mine/components/MessageCenter')
        },
        // {
        //   path: '/mine/netWorkSetting',
        //   name: '配网设置',
        //   element: lazyLoad('Mine/components/NetWorkSetting')
        // },
        {
          path: '/mine/terminalBaseSetting',
          name: '终端基础设置',
          element: lazyLoad('Mine/components/TerminalBaseSetting')
        },
        {
          path: '/mine/newUserGuide',
          name: '新手指引',
          element: lazyLoad('Mine/components/NewUserGuide')
        },
        {
          path: '/mine/question',
          name: '常见问题',
          element: lazyLoad('Mine/components/Question')
        },
        {
          path: '/mine/about',
          name: '关于',
          element: lazyLoad('Mine/components/About')
        },
        {
          path: '/mine/notice',
          name: '通知设置',
          element: lazyLoad('Mine/components/Notice')
        },
        {
          path: '/mine/userSetting',
          name: '用户设置',
          element: lazyLoad('Mine/components/UserSetting')
        },
        {
          path: '/mine/feedback',
          name: '意见与反馈',
          element: lazyLoad('Mine/components/FeedBack')
        },
        {
          path: '/mine/deleteAccount',
          name: '注销账号',
          element: lazyLoad('Mine/components/DeleteAccount')
        },
      ]
    },
    {
      path: '/account',
      name: '会员账户',
      children: [
        {
          path: '/account/login',
          name: '登录',
          element: lazyLoad('Account/Login')
        },
        {
          path: '/account/social-callback',
          name: '第三方登录',
          element: lazyLoad('Account/Login/components/LoginTransfer')
        },
        {
          path: '/account/register',
          name: '注册',
          element: lazyLoad('Account/Register')
        },
        {
          path: '/account/forgetPassword',
          name: '忘记密码',
          element: lazyLoad('Account/ForgetPassword')
        },
      ],
    },
    {
      path: '/dashboard',
      name: '统计图表',
      element: lazyLoad('Dashboard/template/Hems')
    },
    {
      path: '/device',
      name: '统计图表',
      element: lazyLoad('Mine/components/Device')
    },
    {
      path: '/settings',
      name: '设置',
      element: lazyLoad('Settings/template/Hems'),
      children: [
        {
          path: '/settings/all',
          name: '设置',
          element: lazyLoad('Settings')
        },
        {
          path: '/settings/terminalName',
          name: '修改终端名字',
          element: lazyLoad('Settings/components/TerminalName')
        }
      ]
    },
   
    {
      path: '/bindTerminal',
      name: '终端认证',
      element: lazyLoad('BindTerminal')
    },
    // {
    //   path: '/baseTimeSetting',
    //   name: '时间设置',
    //   element: lazyLoad('BaseTimeSetting')
    // },
    {
      path: '/bindDevice',
      name: '绑定设备',
      element: lazyLoad('BindDevice')
    },
    {
      path: '*',
      name: '404页面',
      element: lazyLoad('NotFound')
    },
    // {
    //   path: '/testGuide',
    //   name: '调试测试引导页面',
    //   element: lazyLoad('TestGuide')
    // }
  ]
}
