// 【公共组件】设备管理
export default {
   'device.deviceId': '设备编号',
   'device.terminalNumber': '终端编号',
   'device.deviceMacAddress': '设备mac地址',
   'device.specification': '详细参数',
   'device.detailedParameters': '设备详细参数',
   'device.tips1': '请输入设备名称、设备编号或终端编号',
   'device.search': '搜索',
   'device.tips2': '搜索到以下设备',
   'device.tips3': '设置成功！',
   'device.tips4': '设备xxxx解绑成功！',
   'device.normal': '正常',
   'device.offline': '离线',
   'device.abnormal': '异常',
   'device.turnedOn': '已开启',
   'device.unopened': '未开启',
   'device.delete': '解绑',
   'device.confirmDelete': '确认解绑',
   'device.tips5': '请确认是否解绑设备？',
   'device.neuronErrorTips1': '解绑失败！',
   'device.neuronErrorTips2': '请检查Neuron是否接通电源和网络是否正常后重试。',
   'device.neuronSuccessTips1': '解绑成功！',
   'device.neuronSuccessTips2': 'Neuron解绑后，历史数据将不能访问。',
   'device.neuronSuccessTips3': '如需要查阅历史数据，需重新绑定Neuron后导入历史数据。',
   'device.neuronUnbinding': 'Neuron解绑中，请稍候….',
   'device.neuronConfirmUnbind': '确认解绑',
   'device.neuronUnbindTips1': '解绑Neuron后，Neuron下挂的设备也将一起解绑。',
   'device.neuronUnbindTips2': '确认要解绑Neuron吗？',
   'device.neuronUnbindTips3': '解绑Neuron',
   'device.neuronUnbindTips4': '一键解绑所有下挂设备',
   'device.neuronNumber': 'Neuron编号',
   'device.tips6': '您还没绑定设备，',
   'device.tips7': '去绑定',
   'device.tips8': '确认要解绑Neuron编号为xxx的所有下挂设备吗？',
   'device.tips9': '由于您没有绑定Neuron, 无法访问Neuron所管理的数据, 是否跳转绑定Neuron页面？',
   'device.tips10': '合成设备',
   'device.status': '设备状态',
   'device.unbind': '解绑',
   'device.deviceName': '设备名称',
   'device.childDevice': '子设备',
}
