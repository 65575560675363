// [Common Component] Device Management
export default {
   'device.deviceId': 'Device number',
   'device.terminalNumber': 'Terminal number',
   'device.deviceMacAddress': 'Device mac address',
   'device.specification': 'Detailed parameters',
   'device.detailedParameters': 'Device detailed parameters',
   'device.tips1': 'Please enter the device name, device number or terminal number',
   'device.search': 'Search',
   'device.tips2': 'Searched for the following devices',
   'device.tips3': 'Setting successful! ',
   'device.tips4': 'Device xxxx unbinding successful! ',
   'device.normal': 'Normal',
   'device.offline': 'Offline',
   'device.abnormal': 'Abnormal',
   'device.turnedOn': 'Opened',
   'device.unopened': 'Unopened',
   'device.delete': 'Unbind',
   'device.confirmDelete': 'Confirm unbind',
   'device.tips5': 'Please confirm whether to unbind the device? ',
   'device.neuronErrorTips1': 'Unbind failed! ',
   'device.neuronErrorTips2': 'Please check whether Neuron is powered on and the network is normal and try again. ',
   'device.neuronSuccessTips1': 'Unbind successfully! ',
   'device.neuronSuccessTips2': 'After Neuron is unbound, historical data will not be accessible. ',
   'device.neuronSuccessTips3': 'If you need to view historical data, you need to rebind Neuron and import historical data. ',
   'device.neuronUnbinding': 'Neuron is being unbound, please wait...',
   'device.neuronConfirmUnbind': 'Confirm unbinding',
   'device.neuronUnbindTips1': 'After unbinding Neuron, the devices attached to Neuron will also be unbound. ',
   'device.neuronUnbindTips2': 'Are you sure you want to unbind Neuron? ',
   'device.neuronUnbindTips3': 'Unbind Neuron',
   'device.neuronUnbindTips4': 'Unbind all devices with one click',
   'device.neuronNumber': 'Neuron number',
   'device.tips6': "You haven't bound the device yet,",
   'device.tips7': ' go to binding',
   'device.tips8': 'Are you sure you want to unbind all devices with Neuron number xxx? ',
   'device.tips9': "Since you haven't bound Neuron, you can't access the data managed by Neuron. Do you want to jump to the Neuron binding page? ",
   'device.tips10': 'Synthesize device',
   'device.status': 'Device status',
   'device.unbind': 'Unbind',
   'device.deviceName': 'Device name',
   'device.childDevice': 'Child device',
}