import { createSlice } from '@reduxjs/toolkit'
import * as toolsStorage from '@/tools/storage'
import * as types from '@/types'
import { saveHistoryData } from './dashboard'

const getLang = () => {
  const lang = toolsStorage.getItem('i18nextLng');
  let newLang = '';
  if(!lang || lang === 'zh-CN') {
    newLang = 'zh-CN'
  } else {
    newLang = lang.includes('zh-') ? 'zh-TW' : 'en-US';
  }
  toolsStorage.setItem('i18nextLng', newLang)
  toolsStorage.setItem('ems-lang', newLang)
  return newLang;
}

export const global = createSlice({
  name: 'globalData',
  initialState: {
    // theme: '',
    // toolsStorage.getItem('ems-lang') ||
    language: getLang(),
    isDev: process.env.SYS_ENV !== 'pro',
    currentDomain: `${window.location.protocol}//${window.location.host}`,
    userInfo: toolsStorage.getItem('userInfo'),
    neuronList: null, // Neuron信息
    handingDeviceList: null, // 下挂设备  
    bindDeviceResult: null, // 绑定设备结果
    realTimeNotice: null, // 实时消息通知推送
    deviceControlSettingResult: null, // 设备控制设置结果
    deviceOperationResult: null,  // 设备管理操作结果
    deviceControlDefaultSettings: null, // 设备控制默认设置信息
    historyNewsList: null, // 消息通知列表
  },
  reducers: {
    saveUserInfo: (state: types.PropNameAny, action) => {
      state.userInfo = {...action.payload}
    },
    saveNeuronList : (state: types.PropNameAny, action) => {
      state.neuronList = action.payload
    },
    saveLanguage:  (state: types.PropNameAny, action) => {
      state.language = action.payload
    },
    saveHandingDeviceList:  (state: types.PropNameAny, action) => {
      state.handingDeviceList = action.payload
    },
    saveBindDeviceResult:  (state: types.PropNameAny, action) => {
      state.bindDeviceResult = action.payload
    },
    saveRealTimeNotice:  (state: types.PropNameAny, action) => {
      state.realTimeNotice = action.payload
    },
    saveDeviceControlSettingResult:  (state: types.PropNameAny, action) => {
      state.deviceControlSettingResult = action.payload
    },
    saveDeviceOperationResult: (state: types.PropNameAny, action) => {
      state.deviceOperationResult = action.payload
    },
    saveDeviceControlDefaultSettings: (state: types.PropNameAny, action) => {
      state.deviceControlDefaultSettings = action.payload
    },
    saveHistoryNewsList: (state: types.PropNameAny, action) => {
      state.historyNewsList = action.payload
    },
    
  }
})

export const { 
  saveUserInfo,
  saveNeuronList,
  saveLanguage,
  saveHandingDeviceList,
  saveBindDeviceResult,
  saveRealTimeNotice,
  saveDeviceControlSettingResult,
  saveDeviceOperationResult,
  saveDeviceControlDefaultSettings,
  saveHistoryNewsList
} = global.actions
export const getNeuronList = (state: any) => state.globalData.neuronList
export const getDeviceControlDefaultSettings = (state: any) => state.globalData.deviceControlDefaultSettings
export default global.reducer
