import common from "./zh-CN/common";
import globalHeader from "./zh-CN/globalHeader";
import hems from "./zh-CN/sence/hems";
import multiEv from "./zh-CN/sence/multiEv";
import menu from "./zh-CN/menu";
import components from "./zh-CN/components";
import mine from './zh-CN/mine'

export default {
  ...globalHeader,
  ...menu,
  ...components,
  ...common,
  ...multiEv,
  ...hems,
  ...mine,
};
