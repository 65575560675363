export default {
    "mine.other": "其他设备管理",
    "mine.manage":"管理",
    "mine.searchResult":"搜索结果",
    "mine.confirm":"我确认",
    "mine.deleteSuccess":"删除成功",
    "mine.deleteConfirm": '确定要删除[]设备吗?',
    "mine.deviceSerialNumber":"设备序列号",
    "mine.deleteDevice": "删除设备",
    "mine.searchPlaceholder": "请输入内容",
    
    "mine.userSetting": "用户设置",
    "mine.terminalSetting": "终端基础设置",
    "mine.uploadCloud": "数据上云备份",
    "mine.changeVersion": "检查更新",
    "mine.language": "切换语言",
    "mine.about": "关于",
    "mine.feedback": "意见与反馈",
    "mine.logout": "退出登录",
    "mine.logoutAccount": "注销账号",

    "mine.logoutAccountTips": "注销名字为[]的账号",
    "mine.logoutAccountTips1": "注销[]绑定的账号",
    "mine.logoutAccountCare": "申请注销的关联事项：",
    "mine.logoutAccountCare1": "1. 账号处于安全状态",
    "mine.logoutAccountCare1-1": "账号当前为正常使用状态，不存在被盗、违规、禁用等风险。",
    "mine.logoutAccountCare2": "2. 账号授权、绑定关系已解除",
    "mine.logoutAccountCare2-1": "账号已解除与其他第三方平台的授权等。",
    "mine.logoutAccountCare3": "3. 账号注销后不可找回，用户数据均清除",
    "mine.logoutAccountCare3-1": "注销账号后，绑定的手机以及邮箱等账号以及相关数据，均被清除不可找回。",
    "mine.confirmLogout": "确认注销",
    "mine.logoutSuccess": "账号删除成功！",

    "mine.aboutAlwaysControl": "关于旭衡",
    "mine.aboutAlwaysControlDesc1": "旭衡电子（深圳）有限公司成立于2021年，我们的团队在不间断电源，光伏，充电桩领域拥有20年以上的技术沉淀，拥有成熟，稳定的产品开发平台。",
    "mine.aboutAlwaysControlDesc2": "Always control会为您提供完整多场景全面产品解决方案，适用于家庭/社区、企业/园区，运营商，新能源车紧急道路救援等，并且配套自主开发能量管理系统（Neuron）以及云平台，进行微网间能量智能调度，实时监控与维护，远程运行策略更新，实现使用便捷化，管理智能化，用户效益最大化。",
    "mine.contactInformation": "联系方式",
    "mine.contactAdress": "联系地址",
    "mine.contactAdressDetails": "深圳市宝安区航城街道泰华梧桐工业园7栋401 旭衡电子（深圳）有限公司",
    "mine.contactPhone": "联系电话",
    "mine.contactEmail": "电子邮箱",

    "mine.feedbackIssues": "反馈问题",
    "mine.feedQuantity": "数量",
    "mine.feedAmount": "上限9张图片，最大1MB/张",

    "mine.notice.fail": "获取通知配置失败！",
    "mine.notice.saveSuccess": "通知设置保存成功！",
    "mine.notice.saveFail": "保存失败！",
    "mine.notice.title": "通知设置",
    "mine.notice.batteryLow": "电池电量低通知",
    "mine.notice.chargingFinish": "充电桩充电完成通知",
    "mine.notice.error": "报错通知",
    "mine.notice.update": "升级更新通知",
    "mine.notice.monthly": "月度统计通知",
    "mine.notice.receiveType": "接收方式",

    "mine.language.welcome": "欢迎使用AlwaysControl",
    "mine.language.desc1": "请依据你所在的地区设置语言版本",
    "mine.language.desc2": "你可以随时通过应用程序的设置进行更改",

    "mine.userInfo.avatar":"头像",
    "mine.userInfo.nickname":"昵称",
    "mine.userInfo.password":"密码",
    "mine.userInfo.changePassword":"修改密码",
    "mine.userInfo.setPassword":"设置密码",
    "mine.userInfo.phoneNumber":"电话号码",
    "mine.userInfo.bindPhone":"绑定电话号码",
    "mine.userInfo.bindEmail":"绑定邮箱",
    "mine.loginExpired":"登录过期，请重新登录",
    "mine.userInfo.title":"用户信息",
    "mine.userInfo.bind":"绑定",
    "mine.userInfo.modify": "修改",
    "mine.userInfo.modifyNickname":"修改昵称",
    "mine.userInfo.userNickname":"用户昵称",
    "mine.userInfo.selectPic":"请选择上传的图片",
    "mine.userInfo.saveSuccess":"保存成功",
    "mine.userInfo.uploadFail":"上传失败",
    "mine.userInfo.avatarSaveSuccess":"头像保存成功",
    "mine.userInfo.nameTips1":"用户昵称不能为空",
    "mine.userInfo.nameTips2":"请输入用户昵称"


};
  


