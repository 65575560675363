
const getApiPath = (path: string) => {
    if(path.includes('weather-api/')) {
        return `https://alwayscontrol.net${path}`
    }
    if(process.env.BASE_ENV === 'development') {
        return `//47.121.136.99/test-api${path}`
    }
    return `/prod-api${path}`
}

export {
    getApiPath
}
