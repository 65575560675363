// hems
export default {
    "hems.notice.title": "Notification title",
    "hems.notice.content": "Notification content",
    "hems.notice.operate": "Operation",
    "hems.notice.noRead": "Unread",
    "hems.notice.read": "Read",
    "hems.notice.warning": "Reminder",
    "hems.notice.error": "Abnormal",
    "hems.notice.success": "Statistical settlement",
    "hems.notice.sys": "System information",
    "hems.notice.operateSuccess": "Operation successful",
    "hems.notice.operateTips": "Please select the item to operate",

    "hems.device.deleteTips1": "Please confirm whether to unbind the device:",
    "hems.device.deleteTips2": "Please confirm whether",
    "hems.device.text": 'Equipment',
    "hems.device.deleteTips3": 'Failed to communicate with the device, unable to set the charging pile to disable and enable',
    "hems.device.deleteTips4": 'Failed to communicate with the device, unable to delete the device',
    "hems.device.deleteTips5": 'Unbinding failed',
    "hems.device.deleteTips6": 'Failed to disable and enable',
    
    // 新手指引
    "hems.userGuide.tips1": "Cloud/Local Version Switching",
    "hems.userGuide.tips2": "The local version has limited functions. The cloud version supports more powerful features such as remote control, OTA updates, and weather warnings. If the cloud function is not activated, some features may be limited. The content displayed may differ between the local and cloud versions.",
    "hems.userGuide.tips3": "Real-Time Energy Flow",
    "hems.userGuide.tips4": "Monitor device status in real time.",
    "hems.userGuide.quickStartGuide": "quick start guide",
    "hems.userGuide.homeIntroduction": "Home Introduction",
    "hems.userGuide.history": "History",
    "hems.userGuide.cloudDataBackup": "Cloud Data Backup",
    "hems.userGuide.cloudDataBackupTips": "Click to select cloud data backup to save HEMS local storage, and make better recommendations based on user habits, also helping customers adjust to the most suitable mode.",
    "hems.userGuide.originalData": "Original Data",
    "hems.userGuide.originalDataTips": "Real data collected from the machine. Data may fluctuate and have errors due to external factors. For real device data, this option can be selected. Generally suitable for users researching data accuracy and installers.",
    "hems.userGuide.correctedData": "Corrected Data",
    "hems.userGuide.correctedDataTips": "Data corrected by the algorithm. For optimized and adjusted data, this option can be selected. Generally suitable for ordinary household users.",
    "hems.userGuide.modeDescription": "Mode Description",
    "hems.userGuide.selfUse": "Self-Use",
    "hems.userGuide.selfUseTips": "Selecting Self-Use Mode maximizes the use of photovoltaic power for household use. Generally chosen by households with photovoltaics.",
    "hems.userGuide.emergencyMode": "Emergency",
    "hems.userGuide.emergencyModeTips": "Before a power outage notice, this mode can be selected. Both photovoltaics and the grid will try their best to fully charge the battery to supply power during outages.",
    "hems.userGuide.economyMode": "Economy",
    "hems.userGuide.economyModeTips": "To save on electricity bills, the grid charges the battery during low-tariff periods, and the battery discharges during high-tariff periods. The grid billing module must be filled in to use this mode.",
    "hems.userGuide.selfUseRate": "Self-Use Rate",
    "hems.userGuide.selfUseRateTips": "The self-use rate is calculated from the previous day. It is the amount of photovoltaic power used by household loads, charging stations, batteries, etc., and is updated every 24 hours. Different modes affect the self-consumption rate, which can be adjusted according to actual conditions.",
    "hems.userGuide.todaysData": "Today's Data",
    "hems.userGuide.todaysDataTips": "Through today's data, you can understand the energy yield and other statuses for the day. Data is updated every hour. Economic benefits vary under different modes, which can be adjusted according to actual conditions.",
    "hems.userGuide.energyStatistics": "Energy Statistics",
    "hems.userGuide.energyStatisticsTips": "Currently supports energy statistics, yield statistics, and green energy emissions reduction for household loads, photovoltaics, batteries, charging stations, and the grid. Supports day, month, and year selections.",
    "hems.userGuide.energyBreakdownFunction": "Energy Breakdown Function",
    "hems.userGuide.energyBreakdownFunctionTips": 'Click the "eyes" logo to see energy breakdown changes and better understand your energy usage and sources.',

    "hems.userGuide.details": 'Detailed data display',
    "hems.userGuide.details-1": 'Click on the number to see more content',
    "hems.userGuide.notice": 'Message reminder',
    "hems.userGuide.notice-1": 'Click to see the message',
    "hems.userGuide.viewMode": 'View selection mode',
    "hems.userGuide.chartOprate": 'Chart page operation',
    "hems.userGuide.chartOprate-1": 'In the upper part of the chart page, you can select the corresponding category to view the parameters. Currently supports the energy splitting of household loads, photovoltaics, batteries, charging piles, and power grids. Supports the selection of day, month, and year. ',
    "hems.userGuide.viewFull": 'Full screen function',
    "hems.userGuide.viewFull-1": 'Click full screen to enlarge the image and view the data in full screen',
    "hems.userGuide.viewSplitBefore": 'Before split',
    "hems.userGuide.viewSplitAfter": 'After split',

    "hems.userGuide.intro1": 'Home page operation introduction',
    "hems.userGuide.intro2": 'Home page display introduction',
    "hems.userGuide.intro3": 'Chart page introduction',

    // 首页
    "hems.home.selfuseRate": "Self-use rate",
    "hems.home.percent": "Percent",
    "hems.home.todayData": "Today's data",
    "hems.home.todayPowerGeneration": "Today's power generation",
    "hems.home.salesIncome": "Electricity sales income",
    "hems.home.saved": "Saved for you",
    "hems.home.reduce": "Reduce your carbon footprint",
    "hems.home.tips1": "No data yet, you haven't bound xxxx yet",
    "hems.home.criticalLoad": "Critical Load",
    "hems.home.keyVoltage": "Key voltage",
    "hems.home.householdLoad": "Household Load",
    "hems.home.householdVoltage": "Household voltage",
    "hems.home.batteryHealth": "Battery Health",
    "hems.home.averageTemp": "Average temperature",
    "hems.home.gridLoad": "Grid load",
    "hems.home.toggeleDataSource": "The current display data has been switched to:",
    "hems.home.toggleTips": "Are you sure you want to change?",
    "hems.home.modeSending": "Mode command is being sent, please wait...",
    "hems.home.modeSuccess": "Mode switch command has been sent successfully!",
    "hems.home.toggleDataSourceText": 'The current display data has been switched to:',

     // 图表页面
    "hems.history.energyStatistics": "Energy Statistics",
    "hems.history.revenueStatistics": "Revenue Statistics",
    "hems.history.greenStatistics": "Green Emission Reduction",
    "hems.history.revenueTitle": "Total revenue from electricity sales",
    "hems.history.savingsTitle": "Total savings",
    "hems.history.carbonTitle": "Total reduction in carbon emissions",
    "hems.history.day": "Day",
    "hems.history.month": "Month",
    "hems.history.year": "Year",
    "hems.history.beforeDay": "Previous day",
    "hems.history.afterDay": "Next day",
    "hems.history.beforeMonth": "Previous month",
    "hems.history.afterMonth": "Next month",
    "hems.history.beforeYear": "Previous year",
    "hems.history.afterYear": "Next year",
    "hems.history.totalUse": 'Total usage',
    "hems.history.fullScreen": 'Full screen',
    "hems.history.timeSelect": "Date Selection",
    "hems.home.isOnline": "Whether it is online",
    "hems.home.useStatus": "Use Status",
    "hems.home.workModeText": 'Current work mode:',
    
   
    // 设置页面
    "hems.setting.rmbName": "RMB",
    "hems.setting.currencyType": "Currency",
    "hems.setting.emsSettings": "EMS Settings",
    "hems.setting.advancedSettings": "Advanced settings",
    "hems.setting.terminalName": "Terminal Name",
    "hems.setting.terminalStatus": "Terminal State",
    "hems.setting.terminalStatusConnect": "connected",
    "hems.setting.maximumInflowCurrent": "Maximum household inflow current",
    "hems.setting.electricitySold": "Is selling electricity allowed?",

    "hems.setting.selfuseMode": "Self-use mode",
    "hems.setting.selfuseModeTips": "After selecting the self-use mode, the photovoltaic power will be used to the maximum extent for household use. Generally, households with photovoltaic power will choose this mode.",
    "hems.setting.economicMode": "Economic Mode",
    "hems.setting.economicModeTips": "In order to save electricity expenses, you can set the grid to charge the battery during low electricity price periods and discharge the battery to power the load during high electricity price periods. The grid billing module must be filled in before it can be used.",
    "hems.setting.emergencyMode": "Emergency Mode",
    "hems.setting.emergencyModeTips": "This mode can be selected before receiving a power outage notice. The photovoltaic power plant and the power grid will do their best to fully charge the battery to meet the household load during the power outage.",

    "hems.setting.batteryMode": "Battery Mode",
    "hems.setting.connect": "Is grid charging allowed?",

    "hems.setting.settingUpPowerBackup": "Setting up power backup",
    "hems.setting.settingUpPowerBackupTips1": "Refers to the SOC of the backup energy source during grid outages, which is equivalent to at least xx% of the electricity being stored in the battery, with the rest being used in the other modes，such as self-use or economy mode.",
    "hems.setting.settingUpPowerBackupTips2": "If you want to preserve more energy for use during a grid outage, or if your area is more prone to power outages, you can set a higher reserve percentage.",
    "hems.setting.settingUpPowerBackupTips3": "If you wish to optimize the battery's performance in self-use or economy mode, you can set a lower reserve percentage.",

    "hems.setting.gridStopBackupSource": "Power grid outage backup energy",
    "hems.setting.useSelfPower": "For self-powered",

    "hems.setting.carChargingOffGrid": "When the car is charging off-grid",
    "hems.setting.carChargingOffGridTips": "Using this feature the battery will stop or slow down the charging of the tram to meet your home's power and energy needs during grid termination.",

    "hems.setting.saveForHomeUse": "Save for home use",
    "hems.setting.useWithCar": "Share with vehicle",
    
    "hems.setting.batteryChargingCurrent": "Battery charging current",
    "hems.setting.pcsBatteryChargingOptimization": "Battery charge limit",


    "hems.setting.plugAndCharge": "Plug and charge",
    "hems.setting.plugAndChargeTips": "The charging gun starts charging when it is plugged into the electric car.",
    "hems.setting.scheduleCharging": "Schedule charging",
    "hems.setting.scheduleChargingTips": "Supports scheduled charging, users can schedule the charging start and end time through the Web and APP.",
    "hems.setting.photovoltaicCharging": "Photovoltaic charging",

    "hems.setting.everyday": "Everyday",
    "hems.setting.monday": "Monday",
    "hems.setting.tuesday": "tuesday",
    "hems.setting.wednesday": "Wednesday",
    "hems.setting.thursday": "Thursday",
    "hems.setting.friday": "Friday",
    "hems.setting.saturday": "Saturday",
    "hems.setting.sunday": "Sunday",

    "hems.setting.chargingMode": "Charging Mode",
    "hems.setting.maxElectricity": "Maximum charging current setting",
    "hems.setting.maxPower": "Maximum charging power setting",
    "hems.setting.reservation": "Schedule charging time",

    "hems.setting.gridPriceTips1": "You can refer to the State Grid Power Company of your country to check the charging standards and fill in the form.",
    "hems.setting.gridPriceTips2": "The price of your utility energy varies throughout the day based on energy supply and demand. These times are often referred to as “flats,” “low” (lower prices during times when energy demand is lower), and “high” (higher prices during times when energy demand is higher).",
    "hems.setting.setHighTime": "Please set the high electricity price time period",
    "hems.setting.setFlagTime": "Please set the flat electricity price time period",
    "hems.setting.setLowTime": "Please set the low electricity price time period",
    "hems.setting.buy": "Buy",
    "hems.setting.sell": "Sell",
    "hems.setting.settingsRestored": "Page settings restored!",
    "hems.setting.validateTips1": "The maximum inflow current of a household cannot exceed []A",
    "hems.setting.validateTips2": "The battery charging current must be between [] and []A",
    "hems.setting.validateTips3": "The maximum charge current setting must be between [] and []A",
    "hems.setting.validateTips4": "Please set a scheduled charging time period",
    "hems.setting.validateTips5": "Grid electricity price cannot be empty",
    "hems.setting.validateTips6": "Grid electricity price cannot be empty",
    "hems.setting.validateTips7": "Please set the time period of []",
    "hems.setting.validateTips8": "The time periods of high electricity price, flat electricity price and low electricity price must be set for a full 24 hours!",
    "hems.setting.submitSuccess": "Setup successful",
    "hems.setting.submitSuccessTips": "You have successfully set up HEMS, and HEMS will operate according to the settings. When switching modes, different machines will have different feedback times, so please be patient.",
    "hems.setting.highElectricityPrices":"High electricity prices",
    "hems.setting.flagElectricityPrices":"Flat electricity prices",
    "hems.setting.lowElectricityPrices":"Low electricity prices",

    "hems.setting.workingMode": "Working Mode",
    "hems.setting.validateTipsA": 'The maximum inflow current value is not within the setting range',
    "hems.setting.validateTipsB": 'The battery charging current value is not within the setting range',
    "hems.setting.validateTipsC": 'The maximum charging power value of the charging pile is not within the setting range',
    "hems.setting.validateTipsD": 'Please set the scheduled charging time period! ',
    "hems.setting.validateTipsE": 'The high electricity price, flat electricity price, and low electricity price time period settings must be full 24 hours! ',
    "hems.setting.validateTipsF": 'The buying and selling prices of high electricity prices, flat electricity prices, and low electricity prices must be set! ',
    "hems.setting.terminalId": 'Terminal ID',
    "hems.setting.submitTips1": 'Submitting, it will take some time to communicate with the device, please wait...',
    "hems.setting.submitTips2": 'Communication with the device failed, unable to update the settings control! ',
    "hems.setting.submitTips3": 'Settings update failed! ',
    "hems.setting.submitBtn1": 'Resubmit',
    "hems.setting.submitBtn2": "Refresh the page",
    "hems.setting.range": "Settable range:",
    "hems.setting.allowGridChargingTips": "Allow grid charging when this is enabled. The battery will use the grid to charge your backup reserve. When disabled, the battery will only charge from solar energy.",

    "hems.setting.gridPrice": 'Grid billing',
    "hems.setting.buyTips":"Please enter the buying price",
    "hems.setting.sellTips":"Please enter the selling price",
    "hems.setting.priceTime":"Price time period setting",
    "hems.setting.priceTimeTips":"High electricity price, flat electricity price, and low electricity price time periods must be set, and the total time must be sufficient for 24 hours.",

};
 
     
     
     