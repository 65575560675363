// 公共组件绑定设备
export default {
   'baseSettingTime.tips1': 'Submitting, please wait...',
   'baseSettingTime.tips2': 'successfully Saved',
   'baseSettingTime.tips3': 'Time zone settings cannot be empty',
   'baseSettingTime.tips4': 'Time zone settings cannot be empty',
   'baseSettingTime.tips5': 'Please select a time zone',
   'baseSettingTime.timeSetting': 'Time Settings',
   'baseSettingTime.basicTimeSettings': 'Basic Time Settings',
   'baseSettingTime.timeZoneSetting': 'Time Zone Settings',
   'baseSettingTime.noSearchData': 'No time zone data was found',
   'baseSettingTime.timePlaceholder': 'Please select a time, such as:',
   'baseSettingTime.timeZonePlaceholder': 'Please select a time zone, such as:'
};
