const isEmpty = (data: any) => {
    if(typeof data === 'string') {
        const arr = ['{}', '[]', 'null', '', 'undefined', 'NULL'];
        return arr.includes(data.replace(/\s+/g,''))
    }
    if(typeof data === 'undefined' || data === null) {
        return true
    }
    const arr = ['{}', '[]', 'null'];
    if(data && arr.includes(JSON.stringify(data)) ) {
      return true;
    }
    return false;
}


// 字母排序
const compareLetter = (key: any) => { 
    return (m: any[], n: any[]) => {
        const a = m[key];
        const b = n[key];
        if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
    }
}
// 数字排序
const compareNumber = (property: string) => {
    return (a: {[key: string] : number}, b: {[key: string] : number}) => {
        return a[property] - b[property]
    }
}


const toTrim = (str: string) => {
    return str.replace(/\s+/g, '');
}

const toTrimParams = (params: { [key: string]: any }) => {
    for(let i in params) {
        params[i] = toTrim(params[i]);
    }
   return params;
}

export {
    isEmpty,
    compareLetter,
    toTrim,
    toTrimParams,
    compareNumber
}