// 公共组件
export default {
    'bindTerminal.tips1': 'Terminal information is being verified, please wait...',
    'bindTerminal.tips2': 'Please authenticate',
    'bindTerminal.tips3': 'Please enter the terminal location',
    'bindTerminal.tips4': 'Please select the terminal serial number',
    'bindTerminal.tips5': 'Please fill in the terminal serial number',
    'bindTerminal.terminalSerialNumber': 'Terminal serial number',
    'bindTerminal.terminalLocation': 'Terminal location',
    'bindTerminal.success': 'Terminal binding is successful!',
    'bindTerminal.active': 'Terminal activation authentication',
    'bindTerminal.regText': 'Terminal serial number cannot be empty',
    'bindTerminal.errorText': 'Terminal serial number is incorrect',
    'bindTerminal.area': 'Country',
    'bindTerminal.areaError': 'Country cannot be empty',
    'bindTerminal.areaPlaceholder': 'Please select a country',
    'bindTerminal.tips6': 'Do you want to import historical data',
    'bindTerminal.tips7': 'It is detected that you have bound Neuron before. Please select whether you need to synchronize historical data with the currently bound Neuron. If not synchronized, you will not be able to view the historical data of the previously bound Neuron',
    'bindTerminal.tips8': 'Do not synchronize historical data',
}
 