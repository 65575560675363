// 【公共組件】用戶協議
export default {
    'userAgreement.userNotice': '用戶須知',
    'userAgreement.privacyPolicy': '隱私政策',
    'userAgreement.tips1': '歡迎您選擇深圳旭衡電子有限公司（下文簡稱「旭衡」）軟件服務。我們將按照本隱私政策來處理您的個人信息並保障您信息的安全。',
    'userAgreement.tips2': '旭衡一直專注從事「物聯網 + 新能源」的技術研究、產品研發及行業應用的高新技術企業，竭力為用戶打造這一高價值。您使用本軟件即表示您同意旭衡按照本隱私政策收集、使用、存儲及分享您的個人信息。我們也可能會不時更新本隱私政策。',
    'userAgreement.tips3': '一、如何收集和使用您的個人數據',
    'userAgreement.tips3_1': '個人數據是指單獨使用或結合其他信息使用時能夠確認個人身份的信息。此類數據會在您使用我們產品或服務時被收集。',
    'userAgreement.tips4': '二、我們如何使用Cookie和類似技術 ',
    'userAgreement.tips4_1': '為確保網站正常運轉，我們會在您的計算機或移動設備上存儲名為Cookie的小數據文件。',
    'userAgreement.tips5': '三、旭衡如何披露您的個人數據',
    'userAgreement.tips5_1': '旭衡會在以下情況下，公開披露您的個人信息：',
    'userAgreement.tips5_2': '基於您的明確同意。',
    'userAgreement.tips5_3': '根據法律法規、訴訟或仲裁需要，或按行政或司法機關要求披露。',
    'userAgreement.tips6': '四、旭衡如何保護您的個人數據',
    'userAgreement.tips6_1': '我們非常重視您個人數據的安全。為防止您的個人數據被未經授權的訪問、披露或丟失，我們已採取並將繼續採取各種措施。',
    'userAgreement.tips7': '五、本政策如何更新',
    'userAgreement.tips7_1': '我們可能會不時更新本隱私政策。 ',
    'userAgreement.tips7_2': '當本政策有重大變化時，我們將通過適當的方式通知您。',
    'userAgreement.contactUs': '聯繫我們',
    'userAgreement.contactUsTips': '如果您有任何疑問或意見，請通過以下方式聯繫我們： ',
    'userAgreement.email': '郵箱',
    'userAgreement.contactPhone': '聯繫電話',
    'userAgreement.contactAddress': '聯繫地址',
    'userAgreement.addressDetails': '深圳市寶安區航城街道三圍社區泰華梧桐工業園大暑(7#)棟4層',
    'userAgreement.company': '深圳旭衡電子科技有限公司',
}
 