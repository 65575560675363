import dayjs from "dayjs";

export function getCurrentDate(): string {
  const dateFormat = "YYYY-MM-DD";
  const currentDate = dayjs().format(dateFormat);
  return currentDate;
}

export function getSecondTime(
  date: string = `${new Date()}`,
  timeLimit: string = "00:00:00"
): string | number {
  const dateValue = dayjs(date).format("YYYY-MM-DD");
  return new Date(`${dateValue} ${timeLimit}`).getTime() / 1000;
}


// 获取当前时间
export function getCurrentDateTimeInfo() {
  return {
    time: dayjs().format('YYYY-MM-DD'),
    format: 'YYYY-MM-DD',
    timeType: 'day'
  };
};

export const TIME_RANGE_LIST = [
  {
    end: "01:00",
    type: "1",
    start: "00:00"
  },
  {
    end: "02:00",
    type: "2",
    start: "01:00"
  },
  {
    end: "03:00",
    type: "3",
    start: "02:00"
  },
  {
    end: "04:00",
    type: "4",
    start: "03:00"
  },
  {
    end: "05:00",
    type: "5",
    start: "04:00"
  },
  {
    end: "06:00",
    type: "6",
    start: "05:00"
  },
  {
    end: "07:00",
    type: "7",
    start: "06:00"
  },
  {
    end: "08:00",
    type: "8",
    start: "07:00"
  },
  {
    end: "09:00",
    type: "9",
    start: "08:00"
  },
  {
    end: "10:00",
    type: "10",
    start: "09:00"
  },
  {
    end: "11:00",
    type: "11",
    start: "10:00"
  },
  {
    end: "12:00",
    type: "12",
    start: "11:00"
  },
  {
    end: "13:00",
    type: "13",
    start: "12:00"
  },
  {
    end: "14:00",
    type: "14",
    start: "13:00"
  },
  {
    end: "15:00",
    type: "15",
    start: "14:00"
  },
  {
    end: "16:00",
    type: "16",
    start: "15:00"
  },
  {
    end: "17:00",
    type: "17",
    start: "16:00"
  },
  {
    end: "18:00",
    type: "18",
    start: "17:00"
  },
  {
    end: "19:00",
    type: "19",
    start: "18:00"
  },
  {
    end: "20:00",
    type: "20",
    start: "19:00"
  },
  {
    end: "21:00",
    type: "21",
    start: "20:00"
  },
  {
    end: "22:00",
    type: "22",
    start: "21:00"
  },
  {
    end: "23:00",
    type: "23",
    start: "22:00"
  },
  {
    end: "24:00",
    type: "24",
    start: "23:00"
  }
]