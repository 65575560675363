import request from '@/tools/request'

// 工作模式-回显
// export async function getWorkMode() {
//     return request('/getWorkMode', {
//         method: "GET",
//     })
// }

// 工作模式-切换
export async function setWorkMode(options: { [key: string]: any }) {
    return request('/iot/ems/setting/update/workMode', {
        method: "POST",
        params: options
    })
}

// 自发自用率
export async function getSelfUseRate(options?: { [key: string]: any }) {
    return request('/selfUseRate', {
        method: "GET",
        params: options || {},
    })
}

// 当日数据统计
export async function getDayStatistics(options?: { [key: string]: any }) {
    return request('/dayStatistics', {
        method: "GET",
        params: options || {},
    })
}


// 未拆分图表
export async function getStatistic(options: { [key: string]: any }) {
    return request('/iot/ems/statistic', {
        method: "POST",
        data: options || {},
    })
}

// 拆分图表
export async function getStatisticSplit(options: { [key: string]: any }) {
    return request('/iot/ems/statistic/split', {
        method: "POST",
        data: options || {},
    })
}

// 能量流占比和总统计数
export async function totalPercent(options: { [key: string]: any }) {
    return request('/iot/ems/totalPercent', {
        method: "POST",
        data: options || {},
    })
}


// 电池soc数据
export async function getBatterySocData(options: { [key: string]: any }) {
    return request('/iot/ems/soc/energy', {
        method: "POST",
        data: options || {},
    })
}


// 绿色减排
export async function getRevenue(options: { [key: string]: any }) {
    return request('/revenue', {
        method: "POST",
        data: options || {},
    })
}

// 收益统计
export async function getReduction(options: { [key: string]: any }) {
    return request('/reduction', {
        method: "POST",
        data: options || {},
    })
}

// 获取基础设置信息
export function useGetResetting(params?: {deviceSn?: string}) {
    // return request(`/iot/ems/setting/get/${params?.deviceSn}`, {
    //     method: "GET",
    // })
    return request(`/iot/deviceTerminalSetting/select/converMap/user/${params?.deviceSn}`, {
        method: "GET",
    })
}

// 设置本地基础设置信息
export function usePostResetting(options?: { [key: string]: any }) {
    return request('/setting', {
        method: "POST",
        data: options || {},
    })
}

// 保存本地基本设置
export function saveSetting(params?: {deviceSn?: string}, options?: { [key: string]: any }) {
    return request(`/iot/ems/setting/update?deviceSn=${params?.deviceSn}`, {
        method: "POST",
        data: options,
    })
}


