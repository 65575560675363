import React, { lazy } from 'react'
import { RouteStamp } from './types'
import hems from './routers/hems'
import { CommonLoading } from '@/components/Loading'
import ErrorBoundary from '@/components/ErrorBoundary'

// 路由懒加载
const lazyLoad = (path: string) => {
    const Comp = lazy(() => import(`../src/pages/${path}`))
    return (
      <ErrorBoundary>
        <React.Suspense
          fallback={
            <div style={{ height: '100vh' }}>
              <CommonLoading text="loading..." />
            </div>
          }
        >
          <Comp />
        </React.Suspense>
      </ErrorBoundary>
    )
}

const currentRouter = (() => {
  const name = process.env.SCHEME_NAME
  return hems(lazyLoad)
})()

export default currentRouter
