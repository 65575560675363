// 公共组件数据上云
export default {
   'dataToCloud.tips1': 'The cloud data backup command has been successfully sent. Transferring historical data will take some time. Please wait before switching to the cloud version to check!',
   'dataToCloud.tips2': 'Cloud data backup is off!',
   'dataToCloud.tips3': 'No terminal is bound yet. Redirecting to the terminal binding page.',
   'dataToCloud.tips4': 'No network detected. Please connect to the internet and try again!',
   'dataToCloud.tips5': "Turning off the upload history data function will prevent access to data after leaving the current network's WiFi. Do you want to turn off upload history data function?",
   'dataToCloud.tips6': 'Cloud Data is a data management and analysis platform based on cloud computing technology. It integrates storage, processing, analysis, and sharing functions to provide flexible, scalable, and user-friendly data services. Transferring historical data will take some time. Please be patient!',
   'dataToCloud.tips7': 'Data Backup',
   'dataToCloud.tips8': 'Got it, confirm cloud data backup',
};
