import account from "./account"
import baseSettingTime from "./baseSettingTime"
import bindDevice from "./bindDevice"
import bindTerminal from "./bindTerminal"
import copyright from './copyrightInfo'
import dataToCloud from "./dataToCloud"
import editUserInfo from "./editUserInfo"
import feedBack from "./feedBack"
import notice from "./notice"
import pickerArea from './pickerArea'
import question from "./question"
import toggleProcess from "./toggleProcess"
import useSence from "./useSence"
import versionUpdate from "./versionUpdate"
import userAgreement from "./userAgreement"
import deviceManagement from "./deviceManagement"

export default {
  ...account,
  ...baseSettingTime,
  ...bindDevice,
  ...bindTerminal,
  ...copyright,
  ...dataToCloud,
  ...editUserInfo,
  ...feedBack,
  ...notice,
  ...pickerArea,
  ...question,
  ...toggleProcess,
  ...useSence,
  ...versionUpdate, 
  ...userAgreement,
  ...deviceManagement
}