export default {
  "mine.other": "其他設備管理",
  "mine.manage":"管理",
  "mine.searchResult":"搜索結果",
  "mine.confirm":"我確認",
  "mine.deleteSuccess":"刪除成功",
  "mine.deleteConfirm": '確定要刪除[]設備嗎?',
  "mine.deviceSerialNumber":"設備序列號",
  "mine.deleteDevice": "刪除設備",
  "mine.searchPlaceholder": "請輸入內容",
  
  "mine.userSetting": "用戶設置",
  "mine.terminalSetting": "終端基礎設置",
  "mine.uploadCloud": "數據上雲備份",
  "mine.changeVersion": "檢查更新",
  "mine.language": "切換語言",
  "mine.about": "關於",
  "mine.feedback": "意見與反饋",
  "mine.logout": "退出登錄",
  "mine.logoutAccount": "註銷賬號",

  "mine.logoutAccountTips": "註銷名字為[]的賬號",
  "mine.logoutAccountTips1": "註銷[]綁定的賬號",
  "mine.logoutAccountCare": "申請註銷的關聯事項：",
  "mine.logoutAccountCare1": "1. 賬號處於安全狀態",
  "mine.logoutAccountCare1-1": "賬號當前為正常使用狀態，不存在被盜、違規、禁用等風險。",
  "mine.logoutAccountCare2": "2. 賬號授權、綁定關係已解除",
  "mine.logoutAccountCare2-1": "賬號已解除與其他第三方平台的授權等。",
  "mine.logoutAccountCare3": "3. 賬號註銷後不可找回，用戶數據均清除",
  "mine.logoutAccountCare3-1": "註銷賬號後，綁定的手機以及郵箱等賬號以及相關數據，均被清除不可找回。",
  "mine.confirmLogout": "確認註銷",
  "mine.logoutSuccess": "賬號刪除成功！",

  "mine.aboutAlwaysControl": "關於旭衡",
  "mine.aboutAlwaysControlDesc1": "旭衡電子（深圳）有限公司成立於2021年，我們的團隊在不間斷電源，光伏，充電樁領域擁有20年以上的技術沉澱，擁有成熟，穩定的產品開發平台。",
  "mine.aboutAlwaysControlDesc2": "Always control會為您提供完整多場景全面產品解決方案，適用於家庭/社區、企業/園區，運營商，新能源車緊急道路救援等，並且配套自主開發能量管理系統（Neuron）以及雲平台，進行微網間能量智能調度，實時監控與維護，遠程運行策略更新，實現使用便捷化，管理智能化，用戶效益最大化。",
  "mine.contactInformation": "聯繫方式",
  "mine.contactAdress": "聯繫地址",
  "mine.contactAdressDetails": "深圳市寶安區航城街道泰華梧桐工業園7棟401 旭衡電子（深圳）有限公司",
  "mine.contactPhone": "聯繫電話",
  "mine.contactEmail": "電子郵箱",

  "mine.feedbackIssues": "反饋問題",
  "mine.feedQuantity": "數量",
  "mine.feedAmount": "上限9張圖片，最大1MB/張",

  "mine.notice.fail": "獲取通知配置失敗！",
  "mine.notice.saveSuccess": "通知設置保存成功！",
  "mine.notice.saveFail": "保存失敗！",
  "mine.notice.title": "通知設置",
  "mine.notice.batteryLow": "電池電量低通知",
  "mine.notice.chargingFinish": "充電樁充電完成通知",
  "mine.notice.error": "報錯通知",
  "mine.notice.update": "升級更新通知",
  "mine.notice.monthly": "月度統計通知",
  "mine.notice.receiveType": "接收方式",

  "mine.language.welcome": "歡迎使用AlwaysControl",
  "mine.language.desc1": "請依據你所在的地區設置語言版本",
  "mine.language.desc2": "你可以隨時通過應用程序的設置進行更改",

  "mine.userInfo.avatar":"頭像",
  "mine.userInfo.nickname":"暱稱",
  "mine.userInfo.password":"密碼",
  "mine.userInfo.changePassword":"修改密碼",
  "mine.userInfo.setPassword":"設置密碼",
  "mine.userInfo.phoneNumber":"電話號碼",
  "mine.userInfo.bindPhone":"綁定電話號碼",
  "mine.userInfo.bindEmail":"綁定郵箱",
  "mine.loginExpired":"登錄過期，請重新登錄",
  "mine.userInfo.title":"用戶信息",
  "mine.userInfo.bind":"綁定",
  "mine.userInfo.modify": "修改",
  "mine.userInfo.modifyNickname":"修改暱稱",
  "mine.userInfo.userNickname":"用戶暱稱",
  "mine.userInfo.selectPic":"請選擇上傳的圖片",
  "mine.userInfo.saveSuccess":"保存成功",
  "mine.userInfo.uploadFail":"上傳失敗",
  "mine.userInfo.avatarSaveSuccess":"頭像保存成功",
  "mine.userInfo.nameTips1":"用戶暱稱不能為空",
  "mine.userInfo.nameTips2":"請輸入用戶暱稱"
};



