// 【公共組件】基本時間設置
export default {
   'baseSettingTime.tips1': '正在提交中， 請稍候...',
   'baseSettingTime.tips2': '保存成功',
   'baseSettingTime.tips3': '時間設置不能為空',
   'baseSettingTime.tips4': '時區設置不能為空',
   'baseSettingTime.tips5': '請選擇時區',
   'baseSettingTime.timeSetting': '時間設置',
   'baseSettingTime.basicTimeSettings': '基本時間設置',
   'baseSettingTime.timeZoneSetting': '時區設置',
   'baseSettingTime.noSearchData': '沒有搜索到時區數據數據',
   'baseSettingTime.timePlaceholder': '請選擇時間，如：',
   'baseSettingTime.timeZonePlaceholder': '請選擇時區，如：'
};
