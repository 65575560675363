export default {
  // 模式
  "common.mode.auto": "自髮自用",
  "common.mode.economy": "經濟模式",
  "common.mode.exigency": "應急模式",

  // 設備
  "common.grid": "電網",
  "common.evChargers": "充電樁",
  "common.pv": "光伏",
  "common.inverter": "逆變器",
  "common.pcs": "逆變器+電池",
  "common.terminal": "終端設備",
  "common.airc": "空調",
  "common.engine": "髮動機",
  "common.battery": "電池",
  "common.metter": "電表",
  "common.other": "其他",
  "common.power": "功率",
  "common.powerTotal": "總功率",
  "common.voltage": "電壓",
  "common.electricity": "電量",
  "common.current": "電流",
  "common.homeUsage": "家庭總負載",

  // 數據
  "common.noDataAvailable": "暫無數據哦~",
  "common.tips": "提示",
  "common.reset": "重置",
  "common.currencyUnit": "¥",
  "common.submiting": "正在提交，請稍候...",
  "common.close": "關閉",
  "common.from": "來自",
  "common.useIn": "用於",
  "common.energyPercent": "能量流佔比",
  "common.dataFlow": "數據流",
  "common.getDataIng": "正在獲取數據，請稍候...",
  "common.pageLoading": "頁麵內容正在加載中，請稍候...",
  "common.reseting": "頁麵內容重置，請稍候...",
  "common.save": "保存",
  "common.onlyNumberTips": "隻能填冩數字",
  "common.offline": "離線",
  "common.online": "在線",
  "common.fault":"故障",
  "common.charge": "充電",
  "common.discharge": "放電",
  "common.buy": "買入",
  "common.sell": "賣出",
  "common.loadingInfo": "正在加載中，請稍候...",

  // 
  "common.moreFeatures": "更多功能",
  "common.confirm": "確認",
  "common.saveing": "正在保存中，請稍候...",
  "common.searchPlacholder": "請輸入蒐索關鍵字",
  "common.iSee": "我知道了",
  "common.pageErrorTips1": "哎呀，網頁出錯啦~",
  "common.pageErrorTips2": "請嚐試刷新一下,或聯繫客服~",
  "common.checkTips": "正在校驗，請稍候...",
  "common.back": "返回",
  "common.cancel": "取消",
  "common.optional": "選填",
  "common.required": "必填",
  "common.status": "狀態",
  "common.statusNoDelete": "未刪除",
  "common.statusDelete": "已刪除",
  "common.submit": "提交",
  "common.disable": "停用",
  "common.enable": "啟用",
  "common.year": "年",
  "common.month": "月",
  "common.day": "日",
  "common.version": "版本號：",
  "common.backHome": " 返回首頁",
  "common.backHomeSorry": " 很抱歉，頁麵不小心迷路了",
  "common.requestTips1": "數據正在處理，請勿重複提交",
  "common.requestTips2": "繫統提示",
  "common.requestTips3": "登錄狀態已過期，請重新登錄",
  "common.requestTips4": "重新登錄",
  "common.requestTips5": "無效的會話，或者會話已過期，請重新登錄。",
  "common.requestTips6": "後端接口連接異常",
  "common.requestTips7": "繫統接口請求超時",
  "common.requestTips8": "繫統接口",
  "common.requestTips9": "異常",
  "common.iknow": "我知道了",
};

