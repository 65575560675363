
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Modal } from "antd-mobile";
import * as types from "../types";
import { getApiPath } from "./apiPath";
import * as toolsStorage from "./storage";
import { encryptBase64, encryptWithAes, generateAesKey, decryptWithAes, decryptBase64 } from '@/tools/crypto';
import { encrypt, decrypt } from '@/tools/jsencrypt';
import { errorCode } from '@/tools/errorCode';
import { loginOut } from '@/serviceApi/cloud.common';
import cache from "./cache";
import { tansParams } from "./params";
import { EMS_APP_ENCRYPT, EMS_APP_RSA_PUBLIC_KEY, EMS_APP_RSA_PRIVATE_KEY, EMS_APP_CLIENT_ID, EMS_APP_WEBSOCKET} from './env'
import i18n from 'i18next'


/**
 *
 * 对request进行运行时配置，并且该配置会直接透传到umi-request的全局配置。
 * 后续直接从umi中引入request或者useRequest直接使用，可以说是非常方便。
*/
const filterUrl = ['/user/device/upper/infos', '/system/message/notice/list'];
const encryptHeader = 'encrypt-key';

// 是否显示重新登录
const isRelogin = { show: false };

/** 请求拦截 */
const requestInterceptor = (options: any): any => {
  const headers: any = {
    "Content-Type": options?.headers?.["Content-Type"] || "application/json;charset=utf-8",
    "Content-Language": toolsStorage.getItem("ems-lang") || "zh-CN",
  };
  
  if(!options.url.includes('weather-api')) {  // 户用标记
    headers['scheme'] = '0';
  }

  // 是否需要token
  const isToken = options?.headers?.isToken === false || options?.headers?.isToken === 'false';
  const token: any = toolsStorage.getItem("userToken") || "";
  if (token && !isToken) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const isClientId = options.headers?.isClientId === false;
  if (!isClientId) {
    headers['clientid'] = EMS_APP_CLIENT_ID;
  }

  // 是否需要加密
  const isEncrypt = options?.headers?.isEncrypt === true || options?.headers?.isEncrypt === 'true';


  // get请求映射params参数
  if (options.method === 'GET' && options.params) {
    let url = options.url + '?' + tansParams(options.params);
    url = url.slice(0, -1);
    options.params = {};
    options.url = url;
  }

  // 是否需要防止数据重复提交
  const isRepeatSubmit = options.headers?.repeatSubmit === false;
  if (!isRepeatSubmit && (options.method === 'post' || options.method === 'put')) {
    const requestObj = {
      url: options.url,
      data: typeof options.data === 'object' ? JSON.stringify(options.data) : options.data,
      time: new Date().getTime()
    };
    const sessionObj = cache.session.getJSON('sessionObj');
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj);
    } else {
      const s_url = sessionObj.url; // 请求地址
      const s_data = sessionObj.data; // 请求数据
      const s_time = sessionObj.time; // 请求时间
      const interval = 500; // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = i18n.t('common.requestTips1'); // 数据正在处理，请勿重复提交
        console.warn(`[${s_url}]: ` + message);
        return Promise.reject(new Error(message));
      } else {
        cache.session.setJSON('sessionObj', requestObj);
      }
    }
  }

  if (EMS_APP_ENCRYPT) {
    // 当开启参数加密
    if (isEncrypt && (options.method === 'post' || options.method === 'put')) {
      // 生成一个 AES 密钥
      const aesKey = generateAesKey();
      headers[encryptHeader] = encrypt(encryptBase64(aesKey));
      options.data = typeof options.data === 'object' ? encryptWithAes(JSON.stringify(options.data), aesKey) : encryptWithAes(options.data, aesKey);
    }
  }

   // FormData数据去请求头Content-Type
  if (options.data instanceof FormData) {
    delete headers['Content-Type'];
  }
 
  return {
    // method: options.method,
    ...options,
    url: getApiPath(options.url),
    headers
  };
};

/** 响应拦截 */
const responseInterceptors: any = (response: any, options: any): any => {
  const { data, headers, request, config } = response;
  // if (filterUrl.find(item => config.url.includes(item))) {
  //   response.data.data = response.data.rows; // 把部份接口的返回参数映射为 data
  // }
  if (EMS_APP_ENCRYPT) {
    // 加密后的 AES 秘钥
    const keyStr = headers[encryptHeader];
    // 加密
    if (keyStr != null && keyStr != '') {
      // 请求体 AES 解密
      const base64Str = decrypt(keyStr);
      // base64 解码 得到请求头的 AES 秘钥
      const aesKey = decryptBase64(base64Str.toString());
      // aesKey 解码 data
      const decryptData = decryptWithAes(data, aesKey);
      // 将结果 (得到的是 JSON 字符串) 转为 JSON
      response.data = JSON.parse(decryptData);
    }
  }
  
  // 未设置状态码则默认成功状态
  const code = data.code || data.cod;

  // 获取错误信息
  const msg = errorCode[code] || data.msg || errorCode['default'];

  // 二进制数据则直接返回
  if (request.responseType === 'blob' || request.responseType === 'arraybuffer') {
    return response.data;
  }

  if (code === 401) {
    if (!isRelogin.show) {
      isRelogin.show = true;
      Modal.show({
        title: i18n.t('common.requestTips2'), // 系统提示 
        content: i18n.t('common.requestTips3'), // 登录状态已过期，请重新登录！
        actions: [
          {
            key: 'confirm',
            text: i18n.t('common.requestTips4'), // 去登录
            primary: true,
          },
        ],
        onAction: () => {
          isRelogin.show = false;
          loginOut().then(() => {
            localStorage.clear();
            window.location.href = '/account/login'
          });
        }
      })
      return Promise.reject(response.data)
    } 
    return Promise.reject(i18n.t('common.requestTips5')); // 无效的会话，或者会话已过期，请重新登录。
  } else if (code === 500) { 
    Modal.alert({
      title: i18n.t('common.tips'),
      content: msg
    })
    return Promise.resolve(response.data);
  } else if (code === 601) {
    Modal.alert({
      title: i18n.t('common.tips'),
      content: msg
    })
    return  Promise.resolve(response.data);
  } else if (code !== 200 && code !=='200') {
    Modal.alert({
      title: i18n.t('common.tips'),
      content: msg
    })
    return  Promise.resolve(response.data);
  } else {
    return Promise.resolve(response.data);
  }
};


const errorHandler = (error: any) => {
  // Cannot read properties of undefined (reading 'data')
  // let { response, message} = error;
  if (error.message == 'Network Error') {
    error.message = i18n.t('common.requestTips6'); // 后端接口连接异常
  } else if (error.message.includes('timeout')) {
    error.message = i18n.t('common.requestTips7'); // 系统接口请求超时
  } else if (error.message.includes('Request failed with status code')) {
    error.message = i18n.t('common.requestTips8') + error.message.substr(error.message.length - 3) + i18n.t('common.requestTips9'); // 系统接口, 异常
  }
 
  Modal.alert({
    title: i18n.t('common.tips'),
    content: error.message
  }) 
};

const service = axios.create({
  timeout: 60000,
})

// 请求拦截
service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  return requestInterceptor(config)
}, (error) => {
  errorHandler(error)
})
// 响应拦截
service.interceptors.response.use((res: AxiosResponse) => {
  return responseInterceptors(res)
}, (error: any) => {
  errorHandler(error)
})



export default service

