import common from "./zh-TW/common"
import globalHeader from "./zh-TW/globalHeader"
import multiEv from "./zh-TW/sence/multiEv"
import hems from "./zh-TW/sence/hems"
import menu from "./zh-TW/menu"
import components from "./zh-TW/components"
import mine from './zh-TW/mine'


export default {
  ...globalHeader,
  ...menu,
  ...components,
  ...common,
  ...multiEv,
  ...hems,
  ...mine
}
