import common from "./en-US/common"
import globalHeader from "./en-US/globalHeader"
import menu from "./en-US/menu"
import multiEv from "./en-US/sence/multiEv"
import hems from './en-US/sence/hems'
import components from "./en-US/components"
import mine from "./en-US/mine"
import { min } from "moment"

export default {
  ...globalHeader,
  ...menu,
  ...components,
  ...common,
  ...multiEv,
  ...hems,
  ...mine
}
