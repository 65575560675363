// 【公共組件】常見問題
export default {
    'question.commonQuestions': '常見問題',
    'question.tips1': '本地與雲端有什麼區別？',
    'question.tips1-1': '本地有限制功能，雲端支持更強大更多例如：遠程操控以及ota升級、以及天氣預警功能等。如果沒有開通雲端功能，部分功能會被限制展示，選擇本地和雲端版本看到的內容可能不相同。',
    'question.tips2': '為什麼要選擇雲端數據備份？',
    'question.tips2-1': '選擇雲端數據備份，能節省hems本地的存儲，以及可一根據客戶的用戶習慣做更好的推薦運算，也可以幫助客戶調整最適合自己的模式。',
    'question.tips-3': '切換模式之後為什麼沒有看到很大的效果？',
    'question.tips3-1': '能量管理需要一段時間才能有明顯的效果，建議對比一周以上的數據。',
    'question.tips4': '配網或者添加設備時，找不到要連接的路由器?',
    'question.tips4-1': '*請確保當前路由器已設置密碼。',
    'question.tips4-2': '*請確保當前路由器不需要二次身份與密碼驗證。',
    'question.tips4-3': '*不支持5GWi-Fi網絡連接，請調整到2.4GWi-Fi網絡連接',
    'question.tips4-4': '*請確保路由器未設置隱藏ssid。已設置隱藏ssid的路由器無法在列表中顯示，請修改路由器設置後重新嘗試連接。',
    'question.tips4-5': '上述方法不能解決，建議重啟路由器或者hems嘗試重新連接。',
    'question.tips5': '出現「設備離線」提示怎麼辦?',
    'question.tips5-1': '當出現「設備離線」提示時，可能因為當前網絡環境較差或設備斷電導致。',
    'question.tips5-2': '請嘗試以下操作:',
    'question.tips5-3': '*設備是否連接電源;',
    'question.tips5-4': '*路由器是否能連接外網;',
    'question.tips5-5': '*嘗試斷電重啟設備;',
    'question.tips5-6': '*如果路由器更換密碼，請嘗試重置設備。',
    'question.tips6': '更改密碼或更換路由器後無法使用原有設備怎麼辦?',
    'question.tips6-1': '若更改密碼或更換路由器後需要將設備接通電源後重新配網，並重新選擇路由器熱點、輸入密碼重新連接。點擊我的-配網設置，重新進入配網流程。',
    'question.tips7': '如何添加新設備？',
    'question.tips7-1': '請切換成本地版本進行添加設備的操作，當全新的設備通電後，點擊我的，選擇對應設備的分類，之後選擇添加設備。進行添加設備流程。',
    'question.tips8': '掃描不到添加設備怎麼辦？',
    'question.tips8-1': '可能當前不是在本地版本或者信號有被干擾或者設備已經被綁定，請嘗試重新連接。',
}
 