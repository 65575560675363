// hems
export default {
    "hems.notice.title": "通知標題", 
    "hems.notice.content": "通知內容", 
    "hems.notice.operate": "操作",
    "hems.notice.noRead": "未讀",
    "hems.notice.read": "已讀",
    "hems.notice.warning": "提醒",
    "hems.notice.error": "異常",
    "hems.notice.success": "統計結算",
    "hems.notice.sys": "系統信息",
    "hems.notice.operateSuccess": "操作成功",
    "hems.notice.operateTips": "請選擇要操作的項",
 
    "hems.device.deleteTips1": "請確認是否解綁設備：",
    "hems.device.deleteTips2": "請確認是否",
    "hems.device.text": '設備',
    "hems.device.deleteTips3": '和設備通訊失敗，無法設置充電樁停用啟用',
    "hems.device.deleteTips4": '和設備通訊失敗，無法刪除設備',
    "hems.device.deleteTips5": '解綁失敗',
    "hems.device.deleteTips6": '停用啟用設置失敗',
 
    // 新手指引
    "hems.userGuide.tips1": "雲端/本地版本切換",
    "hems.userGuide.tips2": "本地有限制功能，雲端支持更強大更多例如：遠程操控以及ota升級、以及天氣預警功能等。如果沒有開通雲端功能，部分功能會被限制展示，選擇本地和雲端版本看到的內容可能不相同。",
    "hems.userGuide.tips3": "實時能量流",
    "hems.userGuide.tips4": "能實時觀察設備狀態。",
    "hems.userGuide.quickStartGuide": "新手教程",
    "hems.userGuide.homeIntroduction": "主頁介紹",
    "hems.userGuide.history": "圖表頁面",
    "hems.userGuide.cloudDataBackup": "數據上雲備份",
    "hems.userGuide.cloudDataBackupTips": "點擊選擇雲端數據備份，節省hems本地的存儲，以及可根據客戶的用戶習慣做更好的推薦運算，也可以幫助客戶調整最適合自己的模式。",
    "hems.userGuide.originalData": "原始數據",
    "hems.userGuide.originalDataTips": "從機器上獲取的真實數據。因為外界因素，數據可能會有波動以及誤差，想要看到設備真實數據可以選擇。一般適用於對數據精度有研究的用戶以及安裝人員。",
    "hems.userGuide.correctedData": "修正數據",
    "hems.userGuide.correctedDataTips": "經過算法校正後的數據。想要看到調整優化後的數據可以選擇。一般適用於普通的家庭用戶。",
    "hems.userGuide.modeDescription": "選擇的模式說明",
    "hems.userGuide.selfUse": "自發自用",
    "hems.userGuide.selfUseTips": "選擇自發自用模式後，會最大利用光伏發的電給家庭使用。一般帶有光伏的家庭會選擇這個模式。",
    "hems.userGuide.emergencyMode": "應急模式",
    "hems.userGuide.emergencyModeTips": "當收到斷電預告前，可以選擇該模式，光伏以及電網會盡最大能力給電池充滿電以供滿足停電時的家庭負載。",
    "hems.userGuide.economyMode": "經濟模式",
    "hems.userGuide.economyModeTips": "為了節約電費支出，可以根據電費，設置在低電價時間段電網給電池充電，高電價時間段電池放電給負載供電。必須填寫電網計費模塊才可使用。",
    "hems.userGuide.selfUseRate": "自發自用率",
    "hems.userGuide.selfUseRateTips": "自發自用率是前一天統計的數據，是光伏發電被家庭負載、充電樁、電池等負載適用的量，每24小時更新一次。不同模式下造成的自發自用率不一樣，可以按照實際情況切換模式調整成符合預期效果。",
    "hems.userGuide.todaysData": "今日數據",
    "hems.userGuide.todaysDataTips": "通過今日數據，你能了解當天的能量收益等狀態，數據每小時更新一次。不同模式下造成的經濟效益不一樣，可以按照實際情況切換模式調整成符合預期效果。",
    "hems.userGuide.energyStatistics": "能量統計",
    "hems.userGuide.energyStatisticsTips": "目前支持家庭負載，光伏，電池，充電樁，電網的能量統計、收益統計、綠能減排。支持日月年的選擇。",
    "hems.userGuide.energyBreakdownFunction": "能量拆分功能",
    "hems.userGuide.energyBreakdownFunctionTips": "點擊「眼睛」logo，用戶可以看到能量拆分變化，能更清楚了解自己能源的使用情況以及來源。",
 
    "hems.userGuide.details": '詳細數據展示',
    "hems.userGuide.details-1": '點擊數字，可以看到更多內容',
    "hems.userGuide.notice": '消息提醒',
    "hems.userGuide.notice-1": '點擊可以看到消息',
    "hems.userGuide.viewMode": '查看選擇模式',
    "hems.userGuide.chartOprate": '圖表頁操作',
    "hems.userGuide.chartOprate-1": '圖表頁上半部分可以選擇對應的分類查看參數。目前支持家庭負載，光伏，電池，充電樁，電網的能量拆分。支持日月年的選擇。',
    "hems.userGuide.viewFull": '全屏功能',
    "hems.userGuide.viewFull-1": '點擊全屏可以全屏放大圖查看數據',
    "hems.userGuide.viewSplitBefore": '拆分前',
    "hems.userGuide.viewSplitAfter": '拆分後',
    "hems.userGuide.intro1": '主頁操作按鍵介紹',
    "hems.userGuide.intro2": '主頁顯示部分介紹',
    "hems.userGuide.intro3": '圖表頁介紹',
 
 
    // 首頁
    "hems.home.selfuseRate": "自發自用率",
    "hems.home.percent": "百分比",
    "hems.home.todayData": "今日數據",
    "hems.home.todayPowerGeneration": "今日發電量",
    "hems.home.salesIncome": "賣電收益",
    "hems.home.saved": "已為你節省",
    "hems.home.reduce": "減少碳足跡",
    "hems.home.tips1": "暫無數據，您還沒綁定xxxx",
    "hems.home.criticalLoad": "關鍵負載",
    "hems.home.keyVoltage": "關鍵電壓",
    "hems.home.householdLoad": "家庭負載",
    "hems.home.householdVoltage": "家庭電壓",
    "hems.home.batteryHealth": "電池健康度",
    "hems.home.averageTemp": "平均溫度",
    "hems.home.gridLoad": "電網負荷",
    "hems.home.toggeleDataSource": "當前展示數據已切換為:",
    "hems.home.toggleTips": "您確定要更換嗎？",
    "hems.home.modeSending": "模式指令正在發送中，請稍候...",
    "hems.home.modeSuccess": "模式切換指令已成功發送！",
    "hems.home.isOnline": "是否在線",
    "hems.home.useStatus": "使用狀態",
    "hems.home.workModeText": '當前工作模式：',
    "hems.home.toggleDataSourceText": '當前展示數據已切換為：',
 
     // 圖表頁面
     "hems.history.energyStatistics": "能量統計",
     "hems.history.revenueStatistics": "收益統計",
     "hems.history.greenStatistics": "綠色減排",
     "hems.history.revenueTitle": "賣電總收益",
     "hems.history.savingsTitle": "總節省",
     "hems.history.carbonTitle": "總減少碳排放",
     "hems.history.day": "日",
     "hems.history.month": "月",
     "hems.history.year": "年",
     "hems.history.beforeDay": "前一天",
     "hems.history.afterDay": "後一天",
     "hems.history.beforeMonth": "上一月",
     "hems.history.afterMonth": "後一月",
     "hems.history.beforeYear": "上一年",
     "hems.history.afterYear": "後一年",
     "hems.history.totalUse": '總計使用',
     "hems.history.fullScreen": '全屏',
     "hems.history.timeSelect": "日期選擇",
     
     // 設置頁面
     "hems.setting.rmbName": "人民幣",
     "hems.setting.currencyType": "幣種",
     "hems.setting.emsSettings": "EMS設置",
     "hems.setting.advancedSettings": "高級設置",
     "hems.setting.terminalName": "終端名稱",
     "hems.setting.terminalStatus": "終端狀態",
     "hems.setting.terminalStatusConnect": "已連接",
     "hems.setting.maximumInflowCurrent": "家庭最大流入電流",
     "hems.setting.electricitySold": "是否允許賣電",
 
     "hems.setting.selfuseMode": "自發自用模式",
     "hems.setting.selfuseModeTips": "擇自發自用模式後，會最大利用光伏發的電給家庭使用。一般帶有光伏的家庭會選擇這個模式。",
     "hems.setting.economicMode": "經濟模式",
     "hems.setting.economicModeTips": "為了節約電費支出，可以根據電費，設置在低電價時間段電網給電池充電，高電價時間段電池放電給負載供電。必須填寫電網計費模塊才可使用。",
     "hems.setting.emergencyMode": "應急模式",
     "hems.setting.emergencyModeTips": "當收到斷電預告前，可以選擇該模式，光伏以及電網會盡最大能力給電池充滿電以供滿足停電時的家庭負載。",
 
     "hems.setting.batteryMode": "電池模式",
     "hems.setting.connect": "是否允許電網充電",
 
     "hems.setting.settingUpPowerBackup": "設置用電備份",
     "hems.setting.settingUpPowerBackupTips1": "指的是電網中斷備用能源的SOC，相當於最少xx%電存在電池內部，其餘用於經濟、自發自用模式。",
     "hems.setting.settingUpPowerBackupTips2": "如果您希望在電網停電期間保留更多能源使用，或者您所在的地區更容易發生停電，您可以設置更高的儲備百分比。",
     "hems.setting.settingUpPowerBackupTips3": "如果您希望在自發自用或經濟模式下優化電池的性能，您可以設置較低的儲備百分比。",
 
     "hems.setting.gridStopBackupSource": "電網停電備用能源",
     "hems.setting.useSelfPower": "用於自供電",
 
     "hems.setting.carChargingOffGrid": "汽車離網充電時",
     "hems.setting.carChargingOffGridTips": "使用此功能可在電網終端期間電池將停止或減緩電車充電以滿足您的家庭的電力和能源需求。",
 
     "hems.setting.saveForHomeUse": "保存為家庭使用",
     "hems.setting.useWithCar": "與車輛共用",
     
     "hems.setting.batteryChargingCurrent": "電池充電電流",
     "hems.setting.pcsBatteryChargingOptimization": "電池充電上限",
 
 
     "hems.setting.plugAndCharge": "即插即充",
     "hems.setting.plugAndChargeTips": "充電槍插入電車即開始充電",
     "hems.setting.scheduleCharging": "預約充電",
     "hems.setting.scheduleChargingTips": "支持定時充電，用戶可通過Web以及APP預約充電開始時間和結束時間。",
     "hems.setting.photovoltaicCharging": "光伏充電",
 
     "hems.setting.everyday": "每天",
     "hems.setting.monday": "周一",
     "hems.setting.tuesday": "周二",
     "hems.setting.wednesday": "周三",
     "hems.setting.thursday": "周四",
     "hems.setting.friday": "周五",
     "hems.setting.saturday": "周六",
     "hems.setting.sunday": "周日",
     "hems.setting.chargingMode": "充電模式",
     "hems.setting.maxElectricity": "最大充電電流設置",
     "hems.setting.maxPower": "最大充電功率設置",
     "hems.setting.reservation": "預約充電時間段",
 
     "hems.setting.gridPriceTips1": "可以參考所在國的國網電力公司查詢收費標準以及填寫。",
     "hems.setting.gridPriceTips2": "您的公用事業能源價格根據能源供應和需求全天有所不同。這些時段通常被稱為「平谷」、「低谷」（能源需求較低的低價時段）、「峰谷」（能源需求較高的較高價格時段）。",
     "hems.setting.setHighTime": "請設置高電價時間段",
     "hems.setting.setFlagTime": "請設置平電價時間段",
     "hems.setting.setLowTime": "請設置低電價時間段",
     "hems.setting.buy": "買入價",
     "hems.setting.sell": "賣出價",
     "hems.setting.settingsRestored": "頁面設置已恢復！",
     "hems.setting.validateTips1": "家庭最大流入電流不能超過[]A",
     "hems.setting.validateTips2": "電池充電電流範圍必須在[]到[]A之間",
     "hems.setting.validateTips3": "最大充電電流設置必須在[]到[]A之間",
     "hems.setting.validateTips4": "請設置預約充電時間段",
     "hems.setting.validateTips5": "電網電價必須大於0，不能為空",
     "hems.setting.validateTips6": "電網電價不能為空",
     "hems.setting.validateTips7": "請設置[]的時間段",
     "hems.setting.validateTips8": "高電價、平電價、低電價時間段設置必須滿24小時！",
     "hems.setting.submitSuccess": "設置成功",
     "hems.setting.submitSuccessTips": "你已經成功設置HEMS，HEMS將會按照設置項內容進行操作。切換模式時，不同的機器反饋時間會不一樣，請耐心等候。",
     "hems.setting.highElectricityPrices":"高電價",
     "hems.setting.flagElectricityPrices":"平電價",
     "hems.setting.lowElectricityPrices":"低電價",
 
     "hems.setting.workingMode": "工作模式",
     "hems.setting.validateTipsA": '最大流入電流值不在設置範圍內',
     "hems.setting.validateTipsB": '電池充電電流值不在設置範圍內',
     "hems.setting.validateTipsC": '充電樁最大充電功率值不在設置範圍內',
     "hems.setting.validateTipsD": '請設置預約充電時間段！',
     "hems.setting.validateTipsE": '高電價、平電價、低電價時間段設置必須滿24小時！',
     "hems.setting.validateTipsF": '高電價、平電價、低電價的買入賣出價格必須設置！',
     "hems.setting.terminalId": '終端ID',
     "hems.setting.submitTips1": '正在提交中，和設備通訊需要一些時間，請稍候...',
     "hems.setting.submitTips2": '和設備通訊失敗，無法更新設置控制！',
     "hems.setting.submitTips3": '設置更新失敗！',
     "hems.setting.submitBtn1": '重新提交',
     "hems.setting.submitBtn2": "刷新頁面",
     "hems.setting.range": "可設置範圍：",
     "hems.setting.allowGridChargingTips": "允許電網充電時啟用此功能。電池將使用電網向您的備用儲備充電。禁用時，電池將只從太陽能充電。",
     
     "hems.setting.gridPrice": '電網計費',
     "hems.setting.buyTips":"請輸入買入價",
     "hems.setting.sellTips":"請輸入賣出價",
     "hems.setting.priceTime":"電價時間段設置",
     "hems.setting.priceTimeTips":"高電價、平電價、低電價時間段都必須設置，並且總時間足夠24小時。",
     "hems.setting.modifydeviceName": "修改設備名稱",
     "hems.setting.deviceName": "設備名稱",
     "hems.setting.deviceNameNullTips": "請輸入設備名稱",
};
 
     
     
     
     