/**
 * localStorage: localStorage资源不能滥用，而且要被统一管理
 * 注意：整个项目的localStorage都必须在此模块定义
 * 支持JSON
 */

const localStorage = window.localStorage;

const WHITE_LIST = [
  "userToken", // 用户登录token
  "userInfo", // 用户信息
  "ems-lang", // 缓存当前使用语言
  "i18nextLng",
  "sysTerminalId", // 终端编号
  "terminalInfo", // 终端信息
];

function warn(key: string) {
  if (WHITE_LIST.indexOf(key) === -1) {
    console.warn(`警告：白名单没有(${key})`);
  }
}

export const keys = () => {
  return Object.keys(localStorage);
};

// 获取到的value会自动进行JSON.parse操作
export function getItem(key: string) {
  warn(key);
  let value: any = localStorage.getItem(key);
  try {
    value = JSON.parse(value);
  } catch (error) {
    // to
  }
  return value;
}

// 保存的value如果是object会自动序列化
export function setItem(key: string, value: any) {
  warn(key);
  if (typeof value === "object") {
    // eslint-disable-next-line
    value = JSON.stringify(value);
  }

  return localStorage.setItem(key, value);
}

export function removeItem(key: string) {
  return localStorage.removeItem(key);
}



export function removeAll() {
  WHITE_LIST.forEach((item) => {
    removeItem(item)
  })
}