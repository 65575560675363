// 【公共组件】消息通知
export default {
    'notice.title': '标题',
    'notice.time': '时间',
    'notice.type': '类型',
    'notice.success': '标记成功！',
    'notice.deleteSuccess': '删除成功！',
    'notice.read': '标记已读',
    'notice.delete': '删除',
    'notice.allRead': '全部已读',
    'notice.totalItems': '共xxxx条',
    'notice.tips': '内容正在加载，请稍候...',
    "notice.information": "信息通知",
    "notice.informationCenter": "消息通知中心",
    'notice.allDelete': '全部删除',
}
 