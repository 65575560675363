// 【公共组件】编辑用户信息
export default {
    'editUserInfo.editUserInformation': '编辑用户信息',
    'editUserInfo.modifyUsername': '修改用户名',
    'editUserInfo.changePictures': '修改头像',
    'editUserInfo.savePicturesSuccess': '用户头像保存成功',
    'editUserInfo.saveError': '保存失败',
    'editUserInfo.pictureLimit':'图片必须小于2M',
    'editUserInfo.uploadPicture':'上传头像',
    'editUserInfo.picturePreview':'预览',
    
    'editUserInfo.bindPhoneNumber': '绑定电话号码',
    'editUserInfo.bindEmail': '邮箱绑定',
    'editUserInfo.changePassword': '密码修改',

    'editUserInfo.notificationSettings': '通知设置',
    'editUserInfo.editAccount': '编辑用户账号信息',
    'editUserInfo.waitBingting': '正在绑定中，请稍候...',
    'editUserInfo.collapse': '收起',
    'editUserInfo.modifyBinding': '修改绑定',
    
    'editUserInfo.emailAddress': '邮箱地址',
    'editUserInfo.tips1': '邮箱绑定成功',
    'editUserInfo.tips2': '保存失败',
    'editUserInfo.tips3': '正在发送邮箱验证码，请稍候...',
    'editUserInfo.tips4': '验证码已经发送到邮箱xxxx，请查收',
    'editUserInfo.tips5': '验证码发送失败！',
    'editUserInfo.tips6': '已经绑定手机号',
    'editUserInfo.tips7': '已经绑定邮箱',
    'editUserInfo.emailTips1': '请输入邮箱地址',
    'editUserInfo.emailTips2': '邮箱地址格式不正确',
    'editUserInfo.emailTips3': 'xxxxs后可重发',
    'editUserInfo.emailTips4': '发送验证码',
    'editUserInfo.emailTips5': '邮箱验证码不能为空',
    'editUserInfo.emailTips6': '邮箱验证码不正确',
    'editUserInfo.emailCode': '邮箱验证码',
    'editUserInfo.save': '保存',

    'editUserInfo.phoneTips1': '手机号码绑定成功',
    'editUserInfo.phoneTips2': '正在发送手机验证码，请稍候...',
    'editUserInfo.phoneTips3': '短信验证码已经发送到手机xxxx，请查收',
    'editUserInfo.phoneTips4': '短信验证码发送失败！',
    'editUserInfo.phoneTips5': '请输入电话号码',
    'editUserInfo.phoneTips6': '电话号码格式不正确',
    'editUserInfo.phoneTips7': '发送短信验证码',
    'editUserInfo.phoneTips8': '短信验证码',
    'editUserInfo.phoneTips9': '手机验证码不能为空',
    'editUserInfo.phoneTips10': '手机验证码不正确',
    'editUserInfo.phone': '电话号码',


    'editUserInfo.noticeTips1': '获取通知配置失败！',
    'editUserInfo.noticeTips2': '通知设置保存成功!',
    'editUserInfo.noticeTips3': '电池电量低通知',
    'editUserInfo.noticeTips4': '充电桩完成通电通知',
    'editUserInfo.errorNotification': '报错通知',
    'editUserInfo.updateNotification': '升级更新通知',
    'editUserInfo.monthlyStatisticsNotification': '月度统计通知',
    'editUserInfo.notificationMethod': '接收方式',

    'editUserInfo.userNameTips1': '用户名保存成功',
    'editUserInfo.userNameTips2': '用户名保存失败',
    'editUserInfo.userNameTips3': '请输入用户名',
    'editUserInfo.userNameTips4': '用户名格式不正确，必须数字下划线英文组成',
    'editUserInfo.userNameTips5': '请输入用户名',
    'editUserInfo.userNameTips6': '正在保存，请稍候...',
    'editUserInfo.userName': '用户名',

    'editUserInfo.avatorTips': '点击修改头像',
    'editUserInfo.verifyCodeTips1': '请输入您收到的验证码：',
    'editUserInfo.verifyCodeTips2': '为了您的账号安全，请点击以下按钮给原手机号发送验证码：',
    'editUserInfo.verifyCodeTips3': '发送验证码',
    'editUserInfo.verifyCodeTips4': '原手机验证通过，请输入新的绑定手机号',
    'editUserInfo.verifyCodeTips5': '为了您的账号安全，请点击以下按钮给原邮箱发送验证码：',
    'editUserInfo.verifyCodeTips6': '原邮箱验证通过，请输入新的邮箱',
    'editUserInfo.verifyCodeTips7': '旧密码',
    'editUserInfo.verifyCodeTips8': '请先绑定邮箱！',
    'editUserInfo.verifyCodeTips9': '验证码已发送到邮箱, 请去邮箱查收',
    "editUserInfo.verifyCodeTips10": '，将自动跳转到登录页面',
}
 