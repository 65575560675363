/**
 * 参数处理
 * @param {*} params  参数
 */
export const tansParams = (params: any) => {
    let result = '';
    for (const propName of Object.keys(params)) {
      const value = params[propName];
      const part = encodeURIComponent(propName) + '=';
      if (value !== null && value !== '' && typeof value !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
              const params = propName + '[' + key + ']';
              const subPart = encodeURIComponent(params) + '=';
              result += subPart + encodeURIComponent(value[key]) + '&';
            }
          }
        } else {
          result += part + encodeURIComponent(value) + '&';
        }
      }
    }
    result = result[result.length -1] === '&' ? result.slice(0, -1) : result;
    return result;
};