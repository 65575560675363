// 公共组件编辑用户信息
export default {
    'editUserInfo.editUserInformation': 'Edit User Information',
    'editUserInfo.modifyUsername': 'Modify Username',
    'editUserInfo.changePictures': 'Change pictures',
    'editUserInfo.savePicturesSuccess': 'User avatar saved successfully',
    'editUserInfo.saveError': 'Save failed',
    'editUserInfo.pictureLimit':'The image must be smaller than 2M',
    'editUserInfo.uploadPicture':'Upload an avatar',
    'editUserInfo.picturePreview':'Preview',

    'editUserInfo.bindPhoneNumber': 'Bind Phone Number',
    'editUserInfo.bindEmail': 'Bind Email',
    'editUserInfo.changePassword': 'Change Password',

    'editUserInfo.notificationSettings': 'Notification Settings',
    'editUserInfo.editAccount': 'Edit Account',
    'editUserInfo.waitBingting': 'Binding, please wait...',
    'editUserInfo.collapse': 'Collapse',
    'editUserInfo.modifyBinding': 'Modify Binding',
    
    'editUserInfo.emailAddress': 'Email Address',
    'editUserInfo.tips1': 'Email was bound successfully',
    'editUserInfo.tips2': 'failed to save',
    'editUserInfo.tips3': 'Sending email verification code, please wait...',
    'editUserInfo.tips4': 'Verification code sent to xxxx, please check',
    'editUserInfo.tips5': 'failed Verification code sending !',
    'editUserInfo.tips6': 'Phone number already bound',
    'editUserInfo.tips7': 'Email has been bound',
    'editUserInfo.emailTips1': 'Enter email address',
    'editUserInfo.emailTips2': 'Incorrect email format',
    'editUserInfo.emailTips3': 'Resend in xxxxs',
    'editUserInfo.emailTips4': 'Send SMS code',
    'editUserInfo.emailTips5': 'Email verification code cannot be empty',
    'editUserInfo.emailTips6': 'Incorrect email verification code',
    'editUserInfo.emailCode': 'Email Verification Code',
    'editUserInfo.save': 'Save',

    'editUserInfo.phoneTips1': 'Phone number successfully bound',
    'editUserInfo.phoneTips2': 'Sending SMS verification code, please wait...',
    'editUserInfo.phoneTips3': 'Verification code sent to xxxx, please check',
    'editUserInfo.phoneTips4': 'Verification code sending failed!',
    'editUserInfo.phoneTips5': 'Enter phone number',
    'editUserInfo.phoneTips6': 'Incorrect phone number format',
    'editUserInfo.phoneTips7': 'Send SMS verification code',
    'editUserInfo.phoneTips8': 'SMS Verification Code',
    'editUserInfo.phoneTips9': 'SMS verification code cannot be empty',
    'editUserInfo.phoneTips10': 'Incorrect SMS verification code',
    'editUserInfo.phone': 'Phone Number',


    'editUserInfo.noticeTips1': 'Failed to retrieve notification settings!',
    'editUserInfo.noticeTips2': 'Notification settings saved successfully!',
    'editUserInfo.noticeTips3': 'Low Battery Notification',
    'editUserInfo.noticeTips4': 'Charging Station Power On Notification',
    'editUserInfo.errorNotification': 'Error Notification',
    'editUserInfo.updateNotification': 'Update Notification',
    'editUserInfo.monthlyStatisticsNotification': 'Monthly Statistics Notification',
    'editUserInfo.notificationMethod': 'Notification Method',

    'editUserInfo.userNameTips1': 'Username saved successfully',
    'editUserInfo.userNameTips2': 'Username save failed',
    'editUserInfo.userNameTips3': 'Enter username',
    'editUserInfo.userNameTips4': 'Incorrect username format, must consist of numbers, underscores, and letters',
    'editUserInfo.userNameTips5': 'Enter username',
    'editUserInfo.userNameTips6': 'Saving, please wait...',
    'editUserInfo.userName': 'Username',

    'editUserInfo.avatorTips': 'Click to modify avatar',
    'editUserInfo.verifyCodeTips1': 'Please enter the verification code you received:',
    'editUserInfo.verifyCodeTips2': 'For the security of your account, please click the following button to send the verification code to the original mobile phone number:',
    'editUserInfo.verifyCodeTips3': 'Send verification code',
    'editUserInfo.verifyCodeTips4': 'The original mobile phone verification passed, please enter the new bound mobile phone number',
    'editUserInfo.verifyCodeTips5': 'For the security of your account, please click the following button to send the verification code to the original email:',
    'editUserInfo.verifyCodeTips6': 'The original email verification passed, please enter the new email',
    'editUserInfo.verifyCodeTips7': 'Original password',
    'editUserInfo.verifyCodeTips8': 'Please bind your email first! ',
    'editUserInfo.verifyCodeTips9': 'The verification code has been sent to your mailbox, please check it',
    "editUserInfo.verifyCodeTips10": ', you will be automatically redirected to the login page',
}
 