// hems
export default {
   "hems.notice.title": "通知标题", 
   "hems.notice.content": "通知内容", 
   "hems.notice.operate": "操作",
   "hems.notice.noRead": "未读",
   "hems.notice.read": "已读",
   "hems.notice.warning": "提醒",
   "hems.notice.error": "异常",
   "hems.notice.success": "统计结算",
   "hems.notice.sys": "系统信息",
   "hems.notice.operateSuccess": "操作成功",
   "hems.notice.operateTips": "请选择要操作的项",

   "hems.device.deleteTips1": "请确认是否解绑设备：",
   "hems.device.deleteTips2": "请确认是否",
   "hems.device.text": '设备',
   "hems.device.deleteTips3": '和设备通讯失败，无法设置充电桩停用启用',
   "hems.device.deleteTips4": '和设备通讯失败，无法删除设备',
   "hems.device.deleteTips5": '解绑失败',
   "hems.device.deleteTips6": '停用启用设置失败',

   // 新手指引
   "hems.userGuide.tips1": "云端/本地版本切换",
   "hems.userGuide.tips2": "本地有限制功能，云端支持更强大更多例如：远程操控以及ota升级、以及天气预警功能等。如果没有开通云端功能，部分功能会被限制展示，选择本地和云端版本看到的内容可能不相同。",
   "hems.userGuide.tips3": "实时能量流",
   "hems.userGuide.tips4": "能实时观察设备状态。",
   "hems.userGuide.quickStartGuide": "新手教程",
   "hems.userGuide.homeIntroduction": "主页介绍",
   "hems.userGuide.history": "图表页面",
   "hems.userGuide.cloudDataBackup": "数据上云备份",
   "hems.userGuide.cloudDataBackupTips": "点击选择云端数据备份，节省hems本地的存储，以及可根据客户的用户习惯做更好的推荐运算，也可以帮助客户调整最适合自己的模式。",
   "hems.userGuide.originalData": "原始数据",
   "hems.userGuide.originalDataTips": "从机器上获取的真实数据。因为外界因素，数据可能会有波动以及误差，想要看到设备真实数据可以选择。一般适用于对数据精度有研究的用户以及安装人员。",
   "hems.userGuide.correctedData": "修正数据",
   "hems.userGuide.correctedDataTips": "经过算法校正后的数据。想要看到调整优化后的数据可以选择。一般适用于普通的家庭用户。",
   "hems.userGuide.modeDescription": "选择的模式说明",
   "hems.userGuide.selfUse": "自发自用",
   "hems.userGuide.selfUseTips": "选择自发自用模式后，会最大利用光伏发的电给家庭使用。一般带有光伏的家庭会选择这个模式。",
   "hems.userGuide.emergencyMode": "应急模式",
   "hems.userGuide.emergencyModeTips": "当收到断电预告前，可以选择该模式，光伏以及电网会尽最大能力给电池充满电以供满足停电时的家庭负载。",
   "hems.userGuide.economyMode": "经济模式",
   "hems.userGuide.economyModeTips": "为了节约电费支出，可以根据电费，设置在低电价时间段电网给电池充电，高电价时间段电池放电给负载供电。必须填写电网计费模块才可使用。",
   "hems.userGuide.selfUseRate": "自发自用率",
   "hems.userGuide.selfUseRateTips": "自发自用率是前一天统计的数据，是光伏发电被家庭负载、充电桩、电池等负载适用的量，每24小时更新一次。不同模式下造成的自发自用率不一样，可以按照实际情况切换模式调整成符合预期效果。",
   "hems.userGuide.todaysData": "今日数据",
   "hems.userGuide.todaysDataTips": "通过今日数据，你能了解当天的能量收益等状态，数据每小时更新一次。不同模式下造成的经济效益不一样，可以按照实际情况切换模式调整成符合预期效果。",
   "hems.userGuide.energyStatistics": "能量统计",
   "hems.userGuide.energyStatisticsTips": "目前支持家庭负载，光伏，电池，充电桩，电网的能量统计、收益统计、绿能减排。支持日月年的选择。",
   "hems.userGuide.energyBreakdownFunction": "能量拆分功能",
   "hems.userGuide.energyBreakdownFunctionTips": "点击“眼睛”logo，用户可以看到能量拆分变化，能更清楚了解自己能源的使用情况以及来源。",

   "hems.userGuide.details": '详细数据展示',
   "hems.userGuide.details-1": '点击数字，可以看到更多内容',
   "hems.userGuide.notice": '消息提醒',
   "hems.userGuide.notice-1": '点击可以看到消息',
   "hems.userGuide.viewMode": '查看选择模式',
   "hems.userGuide.chartOprate": '图表页操作',
   "hems.userGuide.chartOprate-1": '图表页上半部分可以选择对应的分类查看参数。目前支持家庭负载，光伏，电池，充电桩，电网的能量拆分。支持日月年的选择。',
   "hems.userGuide.viewFull": '全屏功能',
   "hems.userGuide.viewFull-1": '点击全屏可以全屏放大图查看数据',
   "hems.userGuide.viewSplitBefore": '拆分前',
   "hems.userGuide.viewSplitAfter": '拆分后',
   "hems.userGuide.intro1": '主页操作按键介绍',
   "hems.userGuide.intro2": '主页显示部分介绍',
   "hems.userGuide.intro3": '图表页介绍',


   // 首页
   "hems.home.selfuseRate": "自发自用率",
   "hems.home.percent": "百分比",
   "hems.home.todayData": "今日数据",
   "hems.home.todayPowerGeneration": "今日发电量",
   "hems.home.salesIncome": "卖电收益",
   "hems.home.saved": "已为你节省",
   "hems.home.reduce": "减少碳足迹",
   "hems.home.tips1": "暂无数据，您还没绑定xxxx",
   "hems.home.criticalLoad": "关键负载",
   "hems.home.keyVoltage": "关键电压",
   "hems.home.householdLoad": "家庭负载",
   "hems.home.householdVoltage": "家庭电压",
   "hems.home.batteryHealth": "电池健康度",
   "hems.home.averageTemp": "平均温度",
   "hems.home.gridLoad": "电网负荷",
   "hems.home.toggeleDataSource": "当前展示数据已切换为:",
   "hems.home.toggleTips": "您确定要更换吗？",
   "hems.home.modeSending": "模式指令正在发送中，请稍候...",
   "hems.home.modeSuccess": "模式切换指令已成功发送！",
   "hems.home.isOnline": "是否在线",
   "hems.home.useStatus": "使用状态",
   "hems.home.workModeText": '当前工作模式：',
   "hems.home.toggleDataSourceText": '当前展示数据已切换为：',

    // 图表页面
    "hems.history.energyStatistics": "能量统计",
    "hems.history.revenueStatistics": "收益统计",
    "hems.history.greenStatistics": "绿色减排",
    "hems.history.revenueTitle": "卖电总收益",
    "hems.history.savingsTitle": "总节省",
    "hems.history.carbonTitle": "总减少碳排放",
    "hems.history.day": "日",
    "hems.history.month": "月",
    "hems.history.year": "年",
    "hems.history.beforeDay": "前一天",
    "hems.history.afterDay": "后一天",
    "hems.history.beforeMonth": "上一月",
    "hems.history.afterMonth": "后一月",
    "hems.history.beforeYear": "上一年",
    "hems.history.afterYear": "后一年",
    "hems.history.totalUse": '总计使用',
    "hems.history.fullScreen": '全屏',
    "hems.history.timeSelect": "日期选择",
    
    // 设置页面
    "hems.setting.rmbName": "人民币",
    "hems.setting.currencyType": "币种",
    "hems.setting.emsSettings": "EMS设置",
    "hems.setting.advancedSettings": "高级设置",
    "hems.setting.terminalName": "终端名称",
    "hems.setting.terminalStatus": "终端状态",
    "hems.setting.terminalStatusConnect": "已连接",
    "hems.setting.maximumInflowCurrent": "家庭最大流入电流",
    "hems.setting.electricitySold": "是否允许卖电",

    "hems.setting.selfuseMode": "自发自用模式",
    "hems.setting.selfuseModeTips": "择自发自用模式后，会最大利用光伏发的电给家庭使用。一般带有光伏的家庭会选择这个模式。",
    "hems.setting.economicMode": "经济模式",
    "hems.setting.economicModeTips": "为了节约电费支出，可以根据电费，设置在低电价时间段电网给电池充电，高电价时间段电池放电给负载供电。必须填写电网计费模块才可使用。",
    "hems.setting.emergencyMode": "应急模式",
    "hems.setting.emergencyModeTips": "当收到断电预告前，可以选择该模式，光伏以及电网会尽最大能力给电池充满电以供满足停电时的家庭负载。",

    "hems.setting.batteryMode": "电池模式",
    "hems.setting.connect": "是否允许电网充电",

    "hems.setting.settingUpPowerBackup": "设置用电备份",
    "hems.setting.settingUpPowerBackupTips1": "指的是电网中断备用能源的SOC，相当于最少xx%电存在电池内部，其余用于经济、自发自用模式。",
    "hems.setting.settingUpPowerBackupTips2": "如果您希望在电网停电期间保留更多能源使用，或者您所在的地区更容易发生停电，您可以设置更高的储备百分比。",
    "hems.setting.settingUpPowerBackupTips3": "如果您希望在自发自用或经济模式下优化电池的性能，您可以设置较低的储备百分比。",

    "hems.setting.gridStopBackupSource": "电网停电备用能源",
    "hems.setting.useSelfPower": "用于自供电",

    "hems.setting.carChargingOffGrid": "汽车离网充电时",
    "hems.setting.carChargingOffGridTips": "使用此功能可在电网终端期间电池将停止或减缓电车充电以满足您的家庭的电力和能源需求。",

    "hems.setting.saveForHomeUse": "保存为家庭使用",
    "hems.setting.useWithCar": "与车辆共用",
    
    "hems.setting.batteryChargingCurrent": "电池充电电流",
    "hems.setting.pcsBatteryChargingOptimization": "电池充电上限",


    "hems.setting.plugAndCharge": "即插即充",
    "hems.setting.plugAndChargeTips": "充电枪插入电车即开始充电",
    "hems.setting.scheduleCharging": "预约充电",
    "hems.setting.scheduleChargingTips": "支持定时充电，用户可通过Web以及APP预约充电开始时间和结束时间。",
    "hems.setting.photovoltaicCharging": "光伏充电",

    "hems.setting.everyday": "每天",
    "hems.setting.monday": "周一",
    "hems.setting.tuesday": "周二",
    "hems.setting.wednesday": "周三",
    "hems.setting.thursday": "周四",
    "hems.setting.friday": "周五",
    "hems.setting.saturday": "周六",
    "hems.setting.sunday": "周日",
    "hems.setting.chargingMode": "充电模式",
    "hems.setting.maxElectricity": "最大充电电流设置",
    "hems.setting.maxPower": "最大充电功率设置",
    "hems.setting.reservation": "预约充电时间段",

    "hems.setting.gridPriceTips1": "可以参考所在国的国网电力公司查询收费标准以及填写。",
    "hems.setting.gridPriceTips2": "您的公用事业能源价格根据能源供应和需求全天有所不同。这些时段通常被称为“平谷”、“低谷”（能源需求较低的低价时段）、“峰谷”（能源需求较高的较高价格时段）。",
    "hems.setting.setHighTime": "请设置高电价时间段",
    "hems.setting.setFlagTime": "请设置平电价时间段",
    "hems.setting.setLowTime": "请设置低电价时间段",
    "hems.setting.buy": "买入价",
    "hems.setting.sell": "卖出价",
    "hems.setting.settingsRestored": "页面设置已恢复！",
    "hems.setting.validateTips1": "家庭最大流入电流不能超过[]A",
    "hems.setting.validateTips2": "电池充电电流范围必须在[]到[]A之间",
    "hems.setting.validateTips3": "最大充电电流设置必须在[]到[]A之间",
    "hems.setting.validateTips4": "请设置预约充电时间段",
    "hems.setting.validateTips5": "电网电价必须大于0，不能为空",
    "hems.setting.validateTips6": "电网电价不能为空",
    "hems.setting.validateTips7": "请设置[]的时间段",
    "hems.setting.validateTips8": "高电价、平电价、低电价时间段设置必须满24小时！",
    "hems.setting.submitSuccess": "设置成功",
    "hems.setting.submitSuccessTips": "你已经成功设置HEMS，HEMS将会按照设置项内容进行操作。切换模式时，不同的机器反馈时间会不一样，请耐心等候。",
    "hems.setting.highElectricityPrices":"高电价",
    "hems.setting.flagElectricityPrices":"平电价",
    "hems.setting.lowElectricityPrices":"低电价",

    "hems.setting.workingMode": "工作模式",
    "hems.setting.validateTipsA": '最大流入电流值不在设置范围内',
    "hems.setting.validateTipsB": '电池充电电流值不在设置范围内',
    "hems.setting.validateTipsC": '充电桩最大充电功率值不在设置范围内',
    "hems.setting.validateTipsD": '请设置预约充电时间段！',
    "hems.setting.validateTipsE": '高电价、平电价、低电价时间段设置必须满24小时！',
    "hems.setting.validateTipsF": '高电价、平电价、低电价的买入卖出价格必须设置！',
    "hems.setting.terminalId": '终端ID',
    "hems.setting.submitTips1": '正在提交中，和设备通讯需要一些时间，请稍候...',
    "hems.setting.submitTips2": '和设备通讯失败，无法更新设置控制！',
    "hems.setting.submitTips3": '设置更新失败！',
    "hems.setting.submitBtn1": '重新提交',
    "hems.setting.submitBtn2": "刷新页面",
    "hems.setting.range": "可设置范围：",
    "hems.setting.allowGridChargingTips": "允许电网充电时启用此功能。电池将使用电网向您的备用储备充电。禁用时，电池将只从太阳能充电。",
    
    "hems.setting.gridPrice": '电网计费',
    "hems.setting.buyTips":"请输入买入价",
    "hems.setting.sellTips":"请输入卖出价",
    "hems.setting.priceTime":"电价时间段设置",
    "hems.setting.priceTimeTips":"高电价、平电价、低电价时间段都必须设置，并且总时间足够24小时。",
    "hems.setting.modifydeviceName": "修改设备名称",
    "hems.setting.deviceName": "设备名称",
    "hems.setting.deviceNameNullTips": "请输入设备名称",
};


	
	
	
	