// 【公共組件】版本更新
export default {
    'versionUpdate.tips1': '檢測到新版本，版本更新需要一點等待時間，請確認是否更新！',
    'versionUpdate.tips2': '正在更新中，更新過程可能需要幾分鐘，請稍候幾分鐘再操作~~',
    'versionUpdate.tips3': '版本更新失敗，請確認網絡連接正常後重試',
    'versionUpdate.tips4': '版本更新成功！',
    'versionUpdate.tips5': '當前檢測到無網狀態，請聯網後再更新！',
    'versionUpdate.tips6': '正在更新中，過程可能需要幾分鐘，更新過程中文件替換會出現打不開或者自動刷新的情況，請稍候幾分鐘後手動刷新頁面~~',
    'versionUpdate.tips7': '當前版本已經是最新版本！！',
    'versionUpdate.currentVersion': '當前版本',
    'versionUpdate.updateVersion': '更新版本',
    'versionUpdate.goToUpdate': '去更新',
    "versionUpdate.tips": '提示',
    "versionUpdate.know": '知道了',
}
 