// 公共组件消息通知
export default {
    'notice.title': 'title',
    'notice.time': 'time',
    'notice.type': 'type',
    'notice.success': 'Marked successfully!',
    'notice.deleteSuccess': 'deleted successfully !',
    'notice.read': 'Mark as read.',
    'notice.delete': 'delete',
    'notice.allRead': 'All read',
    'notice.totalItems': 'Total xxxx items',
    'notice.tips': 'Content is loading, please wait...',
    "notice.information": "Notice",
    "notice.informationCenter": "Message notification center",
    'notice.allDelete': 'All delete',
}
 