// 【公共組件】 登錄、忘記密碼、註冊
export default {
    'account.forgetThePassword': '忘記密碼',
    'account.logInDirectly': '直接去登錄',
    'account.tips1': '頁面正在跳轉，請稍候...',
    'account.tips2': '請先查看並同意用戶須知！',
    'account.autoLogin': '自動登錄',
    'account.userLogin': '用戶登錄',
    'account.registerTips': '還沒有賬號？點擊這裡去註冊',
    'account.userEmail': '用戶名/郵箱',
    'account.userEmailTips': '請輸入用戶名/郵箱',
    'account.password': '密碼',
    'account.passwordTips': '請輸入密碼',
    'account.submit': '提交',
    'account.submitTips': '提交中,請稍候...',
    'account.otherLoginTips': '或選擇其他登錄方式',
    'account.userRegistration': '用戶註冊',
    'account.userName': '用戶名',
    'account.userNameTips': '請輸入用戶名',
    'account.emailTips1': '請輸入郵箱地址',
    'account.emailTips2': '郵箱地址格式不正確',
    'account.phone': '電話號碼',
    'account.phoneTips1': '請輸入電話號碼',
    'account.phoneTips2': '電話號碼不正確',
    'account.phoneTips3': '請輸入電話號碼',
    'account.passwordTips1': '請輸入6-30位密碼',
    'account.passwordTips2': '密碼格式不對，密碼由英文數字和下劃線橫線點號自由組成, 長度6-12位',
    'account.passwordTips3': '兩次輸入密碼不一致，請檢查並重新輸入！',
    'account.passwordTips4': '請輸入密碼, 密碼由英文數字和下劃線橫線點號自由組成',
    'account.passwordTips5': '確認密碼',
    'account.passwordTips6': '請輸入確認密碼',
    'account.passwordTips7': '兩次輸入密碼不一致，請檢查並重新輸入！',
    'account.passwordTips8': '請輸入確認密碼',
    'account.passwordTips9': '恭喜您註冊成功！',
    'account.passwordTips10': '您的用戶名是：',
    'account.emailIs': '郵箱是：',
    'account.goToLoginPage': 's後將自動跳轉到登錄頁面',
    'account.goLogin': '去登錄',
    'account.goRegister': '註冊',
    'account.loginSuccess': '登錄成功',
    'account.email': '郵箱',
    'account.sologn': '用心打造專屬於您的能源方案',
    'account.pleaseAgree': '我已閱讀並同意',
    'account.userAgreement': '《用戶協議》',

    "account.code": "驗證碼",
    "account.codeTips": "請輸入驗證碼",
    "account.codeTips2": "驗證碼不正確",

    "account.modifyPasswordByAccount": "賬號修改密碼",
    "account.modifyPasswordByEmail": "郵箱修改密碼",
    "account.getPasswordByAccount": "賬號找回密碼",
    "account.getPasswordByEmail": "郵箱找回密碼",

    "account.sendEmailTips": "驗證碼已發送到郵箱, 請去郵箱查收",
    "account.name": "賬號名",
    "account.newPassword": "新密碼",
    "account.confirmNewPassword": "確認新密碼",
    "account.nameTips": "請輸入賬號名",
    "account.passwordModifySuccess": "密碼修改成功",
    "account.emailCode": "郵箱驗證碼",
    "account.emailCodeTips": "請輸入郵箱驗證碼",
    "account.emailCodeTips2": "郵箱驗證碼不正確",
    "account.passworTips1": "您密碼已重置成功！",
    "account.passwordTips11": "請輸入確認密碼",
    "account.passwordTips12": "請輸入新密碼",
    "account.nickname": "用戶暱稱",
    "account.modifyNickname": "修改用戶暱稱",
    "account.modifyNicknameSuccess": "用戶暱稱修改成功!",
    "account.nicknameTips": "請輸入用戶暱稱",
    'account.nicknameError': '用戶暱稱修改失敗！',

   "account.login": '登入',
    'account.quickRegister': '快速註冊',
    'account.sendCode': '發送驗證碼',
    'account.sendTips': 's後可重發',
    "account.sendEmailIng": '正在發送郵件，請稍候...',
    'account.emailNotEmpty': '郵箱地址不能為空',
    'account.registerAccount': '註冊賬號',
    'account.registerWelcome': '歡迎註冊 Always Control',
    'account.usernameLimit': '用戶名隻能以字母開頭，且隻能包含字母、數字、下劃線，長度在5-15位之間'
}
 