// 公共组件 登录、忘记密码、注册
export default {
    'account.forgetThePassword': 'Forget the password',
    'account.logInDirectly': 'Log in directly',
    'account.tips1': 'The page is loading, please wait...',
    'account.tips2': 'Please read and agree to the user instructions first!',
    'account.autoLogin': 'auto-login',
    'account.userLogin': 'User login',
    'account.registerTips': "Click here to register",
    'account.userEmail': 'user name/email',
    'account.userEmailTips': 'Please enter your username/email',
    'account.password': 'password',
    'account.passwordTips': 'Please enter your password',
    'account.submit': 'submit',
    'account.submitTips': 'Submitting, please wait...',
    'account.otherLoginTips': 'Or choose another login method',
    'account.userRegistration': 'User Registration',
    'account.userName': 'username',
    'account.userNameTips': 'please enter your user name',
    'account.emailTips1': 'please enter your email',
    'account.emailTips2': 'The email address format is incorrect',
    'account.phone': 'telephone number',
    'account.phoneTips1': 'please enter your telephone number',
    'account.phoneTips2': 'Incorrect phone number',
    'account.phoneTips3': 'please enter your telephone number',
    'account.passwordTips1': 'Please enter a 6-30 digit password.',
    'account.passwordTips2': 'The password format is incorrect. It should be composed of letters, numbers, underscores, dashes, and periods. The length should be 6-12 characters.',
    'account.passwordTips3': 'The passwords do not match. Please check and re-enter!',
    'account.passwordTips4': 'Please enter a password, which can be composed of alphanumeric characters, underscores, dashes, and periods.',
    'account.passwordTips5': 'Confirm Password',
    'account.passwordTips6': 'Please enter the confirmation password',
    'account.passwordTips7': 'The passwords you entered twice do not match. Please check and re-enter!',
    'account.passwordTips8': 'Please enter the confirmation password',
    'account.passwordTips9': 'Congratulations on your successful registration!',
    'account.passwordTips10': 'Your username is:',
    'account.emailIs': 'The email address is:',
    'account.goToLoginPage': 'It will automatically jump to the login page after xxxx seconds.',
    'account.goLogin': 'Go to login page',
    'account.goRegister': 'Register',
    'account.loginSuccess': 'Login successful',
    'account.email': 'Email',
    'account.sologn': 'Create your own energy solution',
    'account.pleaseAgree': 'I have read and agree',
    'account.userAgreement': '《User Agreement》',

    "account.code": "Verification code",
    "account.codeTips": "Please enter the verification code",
    "account.codeTips2": "Incorrect verification code",

    "account.modifyPasswordByAccount": "Account password modification",
    "account.modifyPasswordByEmail": "Email password modification",
    "account.getPasswordByAccount": "Account password retrieval",
    "account.getPasswordByEmail": "Email password retrieval",

    "account.sendEmailTips": "The verification code has been sent to your mailbox, please check your mailbox",
    "account.name": "Account name",
    "account.newPassword": "New password",
    "account.confirmNewPassword": "Confirm new password",
    "account.nameTips": "Please enter your account name",
    "account.passwordModifySuccess": "Password modification successful",
    "account.emailCode": "Email verification code",
    "account.emailCodeTips": "Please enter the email verification code",
    "account.emailCodeTips2": "The email verification code is incorrect",
    "account.passworTips1": "Your password has been reset successfully!",
    "account.passwordTips11": "Please enter the confirmation password",
    "account.passwordTips12": "Please enter the new password",
    "account.nickname": "User Nickname",
    "account.modifyNickname": "Modify user nickname",
    "account.modifyNicknameSuccess": "User nickname modified successfully!",
    "account.nicknameTips": "Please enter your user nickname",
    'account.nicknameError': 'User nickname modification failed! ',

    "account.login": 'Login',
    'account.quickRegister': 'Quick registration',
    'account.sendCode': 'Send verification code',
    'account.sendTips': 's, Can resend',
    "account.sendEmailIng": 'Sending email, please wait...',
    'account.emailNotEmpty': 'The email address cannot be empty',
    'account.registerAccount': 'Register account',
    'account.registerWelcome': 'Welcome to register Always Control',
    'account.usernameLimit': 'Usernames can only start with a letter and can only contain letters, numbers, and underscores. The length is between 5 and 15 characters.'
}
 