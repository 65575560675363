import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDeviceTopInfo } from '@/serviceApi/cloud.common'
import { getAllDeviceList, getNoticeMessageList } from '@/serviceApi/common'
import { useGetResetting } from '@/serviceApi/hems'
import { isEmpty } from "@/tools"
import { initSSE } from '@/tools/sse'
import { 
  saveNeuronList, 
  saveHandingDeviceList,
  saveBindDeviceResult, 
  saveRealTimeNotice,
  saveDeviceControlSettingResult,
  saveDeviceOperationResult,
  saveDeviceControlDefaultSettings,
  saveHistoryNewsList
} from '@/store/reducer/global'
import arrayToTree from 'array-to-tree';
import { saveDeviceRealTimeData } from '@/store/reducer/home';
import { getApiPath } from '@/tools/apiPath';



export default() => {
  const dispatch = useDispatch()
  const neuronList = useSelector((state: any) => {
    return state.globalData.neuronList
  })
  const userInfo = useSelector((state: any) => {
    return state.globalData.userInfo
  })

  const [runCount, setRunCount] = useState(0)

  // 获取消息通知列表
  const getNewsList = async() => {
    const res = await getNoticeMessageList({
      pageNum: 1,
      pageSize: 10,
    });
    if(res.code === 200) {
      dispatch(saveHistoryNewsList({
        data: res.data.rows,
        success: res.data.success,
        total: res.data.total,
      }))
    }
  }

  // 获取下挂设备
  const checkHandingDevice = async (deviceSn: string) => {
    const res = await getAllDeviceList()
    if (res.code === 200) {
      let data = arrayToTree(res.data, {
        parentProperty: 'upperDeviceSn',
        customID: 'deviceSn'
      }) || []
      dispatch(saveHandingDeviceList( data.filter(((item: any) => item?.deviceSn === deviceSn))))
    }
  }


  // 获取设备控制设置信息
  const getDeviceControlDefaultInfo = async (deviceSn: string) => {
    const res = await useGetResetting({ 
      deviceSn
    })
    if (res.code === 200) {
      dispatch(saveDeviceControlDefaultSettings(res.data))
    }
  }


  useEffect(() => {
    if(isEmpty(userInfo)) return
    if(!isEmpty(userInfo) && runCount !== 0) return
     // 处理页面刷新设备信息丢失
    if(!neuronList) {
      (async () => {
        try{
          const res: any = await getDeviceTopInfo({ deviceType: 4 })
          if(!isEmpty(res.data)) {
            dispatch(saveNeuronList(res.data))
            await checkHandingDevice(res.data?.[0].deviceSn)
            await getDeviceControlDefaultInfo(res.data?.[0].deviceSn)
            await getNewsList()
          }
        } catch {
        }
       
      })()
    }
    
    // 订阅sse推送
    // let data = JSON.parse("{\"gridDatas\":[{\"id\":1,\"deviceType\":1,\"enable\":1,\"load\":-1,\"online\":1,\"direction\":2,\"phase\":3,\"voltR\":0,\"currentR\":0,\"powerInit\":-1,\"powerCorr\":-1,\"status\":-1}],\"gridCount\":1,\"clDatas\":[{\"id\":1,\"deviceType\":4,\"enable\":1,\"online\":1,\"phase\":3,\"voltR\":231,\"currentR\":0,\"powerInit\":-1,\"powerCorr\":-1,\"status\":-1}],\"clCount\":1,\"flDatas\":[{\"id\":2,\"deviceType\":3,\"enable\":1,\"online\":1,\"phase\":3,\"voltR\":0,\"currentR\":0,\"powerInit\":-1,\"powerCorr\":-1,\"status\":-1}],\"flCount\":1,\"batDatas\":[{\"id\":1,\"deviceType\":2,\"enable\":1,\"online\":1,\"direction\":2,\"volt\":52,\"current\":0,\"powerInit\":0,\"powerCorr\":-1,\"totalCapacity\":4294967,\"SOC\":48,\"SOH\":-1,\"temp\":-1,\"maxTemp\":-1,\"minTemp\":-1,\"status\":-1}],\"batCount\":1,\"pvDatas\":[{\"id\":1,\"deviceType\":5,\"enable\":1,\"online\":0,\"volt\":0,\"current\":0,\"powerInit\":0,\"powerCorr\":-1,\"status\":-1},{\"id\":2,\"deviceType\":5,\"enable\":1,\"online\":0,\"volt\":0,\"current\":0,\"powerInit\":0,\"powerCorr\":-1,\"status\":-1}],\"pvCount\":2,\"evDatas\":[],\"evCount\":0}")
    // dispatch(saveDeviceRealTimeData(data))

    // 设置项提交，包括通知设置
    // {"action":"TERMINAL_UPDATE_SETTING_RESULT:txNpkQwh-1732591434","data":"{\"isUpdate\":false,\"notSyncSetting\":[\"notice\"]}"}
   // //47.121.136.99

    initSSE({
      url: getApiPath('/resource/sse'),
      //url: `/prod-api/resource/sse`,
      callback(type, event) {
          if(type === 'MESSAGE') {
              // 登录成功推送
              if(event.action.includes('LOGIN_NOTICE')) {
                 
              }

              // 首页实时数据
              if(event.action.includes('TERMINAL_REAL_TIME_DATA')) {
                console.log('实时数据:::', JSON.parse(event.data))
                dispatch(saveDeviceRealTimeData(JSON.parse(event.data)))
              }

              // 绑定设备结果
              if(event.action.includes('TERMINAL_DEVICE_BINDING')) {
                dispatch(saveBindDeviceResult(JSON.parse(event.data)))
              }

               // 通知消息推送
              if(event.action.includes('MESSAGE_NOTICE')) {
                 dispatch(saveRealTimeNotice(JSON.parse(event.data)))
              }

              // 设置页面提交设置成功结果
              if(event.action.indexOf('TERMINAL_UPDATE_SETTING_RESULT') > -1) {
                  dispatch(saveDeviceControlSettingResult(JSON.parse(event.data)))
              }

              // 设备管理操作结果反馈
              if(event.action.indexOf('TERMINAL_OPERATION') > -1) {
                  dispatch(saveDeviceOperationResult(JSON.parse(event.data)))
              }

          }
      }
    })
  }, [runCount, userInfo])
  

}
