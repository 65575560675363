// 【公共组件】绑定终端
export default {
    'bindTerminal.tips1': '终端信息校验中，请稍候...',
    'bindTerminal.tips2': '请进行认证',
    'bindTerminal.tips3': '请输入终端所在地',
    'bindTerminal.tips4': '请选择终端序列号',
    'bindTerminal.tips5': '请填写终端序列号',
    'bindTerminal.terminalSerialNumber': '终端序列号',
    'bindTerminal.terminalLocation': '终端所在地',
    'bindTerminal.success': '终端绑定成功!',
    'bindTerminal.active': '终端激活认证',
    'bindTerminal.regText': '终端序列号不能为空',
    'bindTerminal.errorText': '终端序列号不正确',
    'bindTerminal.area': '国家地区',
    'bindTerminal.areaError': '国家地区不能为空',
    'bindTerminal.areaPlaceholder': '请选择国家地区',
    'bindTerminal.tips6': '是否导入历史数据',
    'bindTerminal.tips7': '检测到您之前绑定过Neuron， 请选择是否需要同步历史数据到当前绑定的Neuron中。如不同步，将无法查看之前绑定Neuron的历史数据',
    'bindTerminal.tips8': '不同步历史数据',
}
