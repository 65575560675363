// 【公共组件】版本更新
export default {
    'versionUpdate.tips1': '检测到新版本，版本更新需要一点等待时间，请确认是否更新！',
    'versionUpdate.tips2': '正在更新中，更新过程可能需要几分钟，请稍候几分钟再操作~~',
    'versionUpdate.tips3': '版本更新失败，请确认网络连接正常后重试',
    'versionUpdate.tips4': '版本更新成功！',
    'versionUpdate.tips5': '当前检测到无网状态，请联网后再更新！',
    'versionUpdate.tips6': '正在更新中，过程可能需要几分钟，更新过程中文件替换会出现打不开或者自动刷新的情况，请稍候几分钟后手动刷新页面~~',
    'versionUpdate.tips7': '当前版本已经是最新版本！！',
    'versionUpdate.currentVersion': '当前版本',
    'versionUpdate.updateVersion': '更新版本',
    'versionUpdate.goToUpdate': '去更新',
    "versionUpdate.tips": '提示',
    "versionUpdate.know": '知道了',
}
 