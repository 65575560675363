export default {
  // 模式
  "common.mode.auto": "自发自用",
  "common.mode.economy": "经济模式",
  "common.mode.exigency": "应急模式",

  // 设备
  "common.grid": "电网",
  'common.evChargers': '充电桩',
  'common.pv': '光伏',
  'common.inverter': '逆变器',
  'common.pcs': '逆变器+电池',
  'common.terminal': '终端设备',
  'common.airc': '空调',
  'common.engine': '发动机',
  'common.battery': '电池',
  'common.metter': '电表',
  'common.other': '其他',
  "common.power": "功率",
  "common.powerTotal": "总功率",
  "common.voltage": "电压",
  "common.electricity": "电量",
  "common.current": "电流",
  "common.homeUsage": "家庭总负载",

  // 数据
  "common.noDataAvailable": '暂无数据哦~',
  'common.tips': '提示',
  "common.reset": "重置",
  "common.currencyUnit": '¥',
  "common.submiting": '正在提交，请稍候...',
  "common.close": '关闭',
  "common.from": '来自',
  "common.useIn": '用于',
  "common.energyPercent": '能量流占比',
  "common.dataFlow": "数据流",
  "common.getDataIng": '正在获取数据，请稍候...',
  "common.pageLoading": '页面内容正在加载中，请稍候...',
  "common.reseting": "页面内容重置，请稍候...",
  "common.save": '保存',
  "common.onlyNumberTips": "只能填写数字",
  "common.offline": "离线",
  "common.online": "在线",
  "common.fault":"故障",
  "common.charge": "充电",
  "common.discharge": "放电",
  "common.buy": "买入",
  "common.sell": "卖出",
  "common.loadingInfo": '正在加载中，请稍候...',

  // 
  "common.moreFeatures": "更多功能",
  "common.confirm": '确认',
  "common.saveing": "正在保存中，请稍候...",
  "common.searchPlacholder": "请输入搜索关键字",
  "common.iSee": '我知道了',
  "common.pageErrorTips1": "哎呀，网页出错啦~",
  "common.pageErrorTips2": "请尝试刷新一下,或联系客服~",
  "common.checkTips": "正在校验，请稍候...",
  "common.back": "返回",
  "common.cancel": "取消",
  "common.optional": "选填",
  "common.required": "必填",
  "common.status": "状态",
  "common.statusNoDelete": "未删除",
  "common.statusDelete": "已删除",
  "common.submit": '提交',
  "common.disable": "停用",
  "common.enable": "启用",
  "common.year": '年',
  "common.month": '月',
  "common.day": '日',
  "common.version": '版本号：',
  "common.backHome": ' 返回首页',
  "common.backHomeSorry": ' 很抱歉，页面不小心迷路了',
  "common.requestTips1": '数据正在处理，请勿重复提交',
  "common.requestTips2": "系统提示",
  "common.requestTips3": "登录状态已过期，请重新登录",
  "common.requestTips4": "重新登录",
  "common.requestTips5": "无效的会话，或者会话已过期，请重新登录。",
  "common.requestTips6": "后端接口连接异常",
  "common.requestTips7": "系统接口请求超时",
  "common.requestTips8": '系统接口',
  "common.requestTips9": '异常',
  "common.iknow": '我知道了',
};

